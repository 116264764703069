import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { formatRoutePathVitecNextOrderProduct } from '../../../../pure/libs/RoutePath'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { smallSpacing } from '../enums/Spacings'
import { useLeopardOrder, useVitecNOOrder } from '../hooks/QueryHooks'
import { useNavigate } from '../hooks/useNavigate'
import { isFinished } from '../libs/JaguarHelper'
import { Texts } from '../libs/Texts'
import Box from './Box'
import { DashboardListItemDetails } from './DashboardListItemDetails'
import { DashboardListItemStatus } from './DashboardListItemStatus'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { BIG_ICON_SIZE, DashboardStatusItemViewPopperRowForwardArrowBlack } from './Icons'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'
import { getLeopardOrderIdForOrder } from '../../../../pure/libs/getLeopardOrderId'
import { useJaguarResultsExperimental } from '../hooks/useJaguarResults'
import { JaguarResultResponse } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'

export default function DashboardListItem(props: VitecNextOrderProductProps) {
  const { vitecNextProduct } = props
  const { textKey, imageKey } = VitecNextProductInfoItems[vitecNextProduct]
  const navigate = useNavigate()
  const { data: vitexNextOrder } = useVitecNOOrder(props.vitecNextOrderId)
  const { data: leopardOrder } = useLeopardOrder(
    getLeopardOrderIdForOrder({ vitecNextProduct, vitecNextOrder: vitexNextOrder })
  )
  const { data: results } = useJaguarResultsExperimental<JaguarResultResponse>(props.vitecNextOrderId, vitecNextProduct)
  return (
    <Box fullWidth>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <Box
              onClick={(e) => {
                e.stopPropagation()
                navigate(formatRoutePathVitecNextOrderProduct(props))
              }}
              visibility={isFinished({ leopardOrder, results }) ? 'visible' : 'hidden'}
            >
              <DashboardStatusItemViewPopperRowForwardArrowBlack />
            </Box>
          }
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
              transform: 'rotate(0deg)'
            }
          }}
        >
          <Box right direction="row" align="center" fullWidth justify="space-between">
            <Box>
              <FigmaImageContainer imageKey={imageKey} width={BIG_ICON_SIZE} />
            </Box>
            <Box left spacing={smallSpacing} fullWidth>
              <FigmaText styleTextKey={Texts.dashboardAutoCaptioningTitleCaptioningTitleg} textKey={textKey} />
            </Box>
            <Box>
              <DashboardListItemStatus {...props} />
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <DashboardListItemDetails {...props} />
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
