import { defaultSpacing } from '../enums/Spacings'
import { MENU_HEIGHT } from '../libs/HardCodedSizes'
import Images from '../../../../pure/libs/AppImages'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'

import styled from '@emotion/styled'
import config from '../Config'
import ZIndices from '../enums/Zindices'
import Colors, { PrimaryColor, SecondaryColor } from '../../../../pure/libs/Colors'
import { BoxProps } from './Box'
import ErrorBoundaryView from './ErrorBoundaryView'
import FooterLoggedIn from './FooterLoggedIn'
import Menu from './Menu'
import { PropsWithChildren } from 'react'

const OFFSET = 6 // if this is not here you get a white line at the bottom of the page

export const Layout: React.FC<
  PropsWithChildren<{
    enableFooter?: boolean
    enableMenu?: boolean
    fullHeight?: boolean
    menuZIndex?: ZIndices
    Container?: React.FC<BoxProps>
    enableRightBottomImage?: boolean
    MenuComponent?: React.FC
  }>
> = ({
  children,
  enableFooter = true,
  enableMenu = true,
  menuZIndex = ZIndices.regular,
  Container = LayoutContinaner,
  MenuComponent = Menu,
  enableRightBottomImage = true
}) => {
  if (config.enableServiceUnavilablePage) return <ErrorBoundaryView />

  const height = window.innerHeight - MENU_HEIGHT + OFFSET

  return (
    <Container fullWidth align="center" justify="space-between">
      <Box fullWidth>
        {enableMenu && (
          <Box fullWidth position="relative" zIndex={menuZIndex}>
            <MenuComponent />
          </Box>
        )}
        <ContainerWithGradient fullWidth position="relative" style={{ minHeight: height }}>
          {enableRightBottomImage && (
            <Box position="fixed" style={{ bottom: `-${defaultSpacing}`, right: 0 }}>
              <FigmaImageContainer imageKey={Images.pngCity} />
            </Box>
          )}
          {children}
        </ContainerWithGradient>
      </Box>
      <Box fullWidth>{enableFooter && <FooterLoggedIn />}</Box>
    </Container>
  )
}

export const LayoutContinaner = styled(Box)`
  min-height: 100vh;
  background-color: ${Colors.secondary100};
  overflow-x: hidden;
`

export const ContainerWithGradient = styled(Box)`
  min-height: 100vh;
  background: var(--LinearVertical, linear-gradient(180deg, ${PrimaryColor} 0%, ${SecondaryColor} 100%));
`
