import React from 'react'
import Box from './Box'
import { Layout } from './Layout'
import { VitecNextOrderRedirectPage } from '../pages/DashboardPage'
import MenuVitecNext from './MenuVitecNext'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { GoToLocalDevEnvironment } from './GoToLocalDevEnvironment'

export const VitecNextOrderProduct: React.FC<VitecNextOrderProductProps> = (props) => {
  const { vitecNextOrderId, vitecNextProduct } = props
  const item = VitecNextProductInfoItems[vitecNextProduct]

  if (!item) return <VitecNextOrderRedirectPage vitecNextOrderId={vitecNextOrderId} />

  const { PreviewComponent } = item

  return (
    <Layout enableFooter={false} MenuComponent={MenuVitecNext} enableRightBottomImage={false}>
      <Box fullWidth>{PreviewComponent && <PreviewComponent {...props} />}</Box>
      <GoToLocalDevEnvironment />
    </Layout>
  )
}
