import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { LiveProductNames } from '../../../../pure/leopard/VitecNextProduct'
import { AppTexts } from '../../../../pure/libs/AppTexts'
import Colors from '../../../../pure/libs/Colors'
import { groupBy } from '../../../../pure/libs/Common'
import { formatRoutePathVitexNextOrder } from '../../../../pure/libs/RoutePath'
import { BorderRadixes } from '../enums/BorderRadixes'
import { reallyBigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { useLeopardOrdersForEstate, useVitecNOOrder } from '../hooks/QueryHooks'
import { useAreGazelleProductsEnabled } from '../hooks/useIsProductEnabled'
import { dayjs } from '../libs/DayJsHelper'
import Images from '../libs/Images'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import Box from './Box'
import Button from './Button'
import ErrorBoundaryView from './ErrorBoundaryView'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { Layout } from './Layout'
import { LoaderGreen } from './LoaderGreen'
import MenuVitecNext from './MenuVitecNext'
import ProgressBar from './ProgressBar'
import { VitecNextOrderItemLongWaitFigmaText } from './VitecNextOrderItemLongWaitFigmaText'
import { VitecNextOrderLandingItem } from './VitecNextOrderLandingItem'
import { useLatestLeopardOrders } from './useLatestLeopardOrders'

export const VitecNextOrderLanding = ({ vitecNextOrderId }: { vitecNextOrderId?: string }) => {
  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)
  const navigate = useNavigate()
  useAreGazelleProductsEnabled({ vitecNextOrderId })

  const { data: leopardOrders = [] } = useLatestLeopardOrders(vitecNextOrder)

  const isDone = leopardOrders.length > 0 && leopardOrders.every((v) => !!v?.finishedAt)
  const isProcessing = !isDone
  const finishedAt = leopardOrders
    .map((v) => v?.finishedAt)
    .sort()
    .filter(Boolean)[0]

  return <ErrorBoundaryView />

  return (
    <Layout enableFooter={false} MenuComponent={MenuVitecNext} enableRightBottomImage={false}>
      <Box fullPadding spacing={reallyBigSpacing} fullWidth align="center">
        <Container fullWidth>
          <Box fullWidth direction="row" align="center" justify="space-between">
            <Box>
              <FigmaText
                styleTextKey={Texts.dashboardAllProgressViewAllProgressHeader}
                textKey={AppTexts.adCreatorWelcomeEmptyState}
              />
            </Box>
            <Box>
              <FigmaText
                textKey={Texts.cssDashboardAllProgressViewAllProgressHeaderAddress}
                text={vitecNextOrder?.address || ''}
              />
            </Box>
          </Box>
          <Box fullWidth top>
            <WhiteContainer fullWidth fullPadding>
              <Box fullWidth>
                <Box fullWidth direction="row" align="center">
                  <Box>
                    {isProcessing && <LoaderGreen />}
                    {isDone && <FigmaImageContainer imageKey={Images.greenRoundedCheckmark} />}
                  </Box>
                  <Box left spacing={smallSpacing}>
                    {isProcessing && <FigmaText textKey={Texts.dashboardAllProgressViewGeneratingLabel} />}
                    {isDone && (
                      <FigmaText
                        styleTextKey={Texts.dashboardAllProgressViewGeneratingLabel}
                        textKey={Texts.standardNotificationCiCompleted}
                        text={`${getFigmaText(Texts.standardNotificationCiCompleted)} (${dayjs(finishedAt).format(
                          'DD/M HH:mm'
                        )})`}
                      />
                    )}
                  </Box>
                </Box>
                {isProcessing && (
                  <Box top fullWidth>
                    <ProgressBar color="success" />
                  </Box>
                )}
              </Box>
              <Box top fullWidth>
                <InnerContainer fullPadding spacing={smallSpacing} fullWidth>
                  {LiveProductNames.map((vitecNextProduct, i) => {
                    if (!vitecNextOrder) return null
                    return (
                      <Box fullWidth top={i > 0} spacing={smallSpacing} key={i}>
                        <VitecNextOrderLandingItem
                          vitecNextOrder={vitecNextOrder}
                          vitecNextProduct={vitecNextProduct}
                        />
                      </Box>
                    )
                  })}
                </InnerContainer>
              </Box>
              <Box top spacing={tinySpacing} alignText="left" fullWidth>
                <VitecNextOrderItemLongWaitFigmaText
                  vitecNextOrder={vitecNextOrder}
                  figmaTextProps={{ styleTextKey: Texts.dashboardAllProgressViewAllProgressInfo }}
                />
              </Box>
              <Box top fullWidth direction="row" justify="space-between">
                <Box />
                <Button
                  textKey={Texts.dashboardAllProgressViewCtaOpenDashboard}
                  onClick={() => vitecNextOrderId && navigate(formatRoutePathVitexNextOrder(vitecNextOrderId))}
                />
              </Box>
            </WhiteContainer>
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}
const Container = styled(Box)`
  max-width: 900px;
`
const WhiteContainer = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.regular};
`
const InnerContainer = styled(Box)`
  background-color: ${Colors.neutral30};
  border-radius: ${BorderRadixes.regular};
`
