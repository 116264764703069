import styled from '@emotion/styled'
import Colors from '../../../../pure/libs/Colors'
import { Error, Success, SuccessBorder, Warning, WarningBorder } from '../../../../pure/libs/ColorsExperimental'
import { ImageKey, TextKey } from '../../../../pure/types/Antiloop'
import { BorderRadixes } from '../enums/BorderRadixes'
import { smallSpacing } from '../enums/Spacings'
import Images from '../libs/Images'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { BIG_ICON_SIZE } from './Icons'
import Loader from './Loader'
import { DEFAULT_ICON_SIZE } from './Icon'
import { VitecNextProductStatusProps } from '../types/VitecNextProductTypes'
import { Texts } from '../libs/Texts'

export type VitecNextOrderItemStatusViewType = 'success' | 'warning' | 'error' | 'processing'

export type VitecNextOrderItemStatusViewProps = {
  textKey?: TextKey
  text?: string
  type: VitecNextOrderItemStatusViewType
  onClick?: () => unknown
  statusProps?: Partial<VitecNextProductStatusProps>
}

export const VitecNextOrderItemStatusView = (props: VitecNextOrderItemStatusViewProps) => {
  const { textKey = Texts.standardNotificationMissingDocument, text, type, onClick, statusProps } = props
  const ViewComponent = statusProps?.ViewComponent

  if (ViewComponent) return <ViewComponent {...props} />

  const Container = type === 'success' ? SuccessContainer : type === 'error' ? ErrorContainer : WarningContainer
  let imageKey: ImageKey | undefined = undefined

  if (!imageKey && type === 'success') imageKey = Images.roundCheckIconWhite
  if (!imageKey && type === 'error') imageKey = Images.warningIcon
  if (!imageKey && type === 'warning') imageKey = Images.warningIcon

  return (
    <Container fullWidth fullPadding spacing={smallSpacing} align="center" onClick={onClick} link={!!onClick}>
      <Box direction="row" align="center">
        {imageKey && (
          <Box>
            <FigmaImageContainer imageKey={imageKey} width={DEFAULT_ICON_SIZE} />
          </Box>
        )}
        {type === 'processing' && (
          <Box>
            <Loader $loaderColor={Colors.white} size={BIG_ICON_SIZE} />
          </Box>
        )}
        <Box left spacing={smallSpacing}>
          <FigmaText textKey={textKey} text={text} />
        </Box>
      </Box>
    </Container>
  )
}

const BadgeContainer = styled(Box)`
  border-radius: ${BorderRadixes.regular};
`

const SuccessContainer = styled(BadgeContainer)`
  background-color: ${Success};
  border: 1px solid ${SuccessBorder};
`

const WarningContainer = styled(BadgeContainer)`
  background-color: ${Warning};
  border: 1px solid ${WarningBorder};
`

const ErrorContainer = styled(BadgeContainer)`
  background-color: ${Error};
  border-radius: ${BorderRadixes.regular};
`
