import Box from './Box'
import Loader from './Loader'
import { VitecImage } from './VitecImage'

import { Caption } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { UploadedFileInfoMetadata } from '../../../../pure/leopard/UploadedFileInfoForServer'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { Texts } from '../libs/Texts'
import { Checkbox } from './Checkbox'
import FigmaText from './FigmaText'

const FIGMA_IMAGE_WIDTH = 150

export type SelectedCaptions = {
  [value: string]: Caption | undefined
}

export function VitecNextOrderProductImageCaptionsResultExperiemental({
  caption,
  index,
  enableCheckbox = true,
  selectedCaptions,
  leopardOrder,
  onSelectCaption
}: {
  caption?: Caption
  index: number
  enableCheckbox?: boolean
  selectedCaptions?: SelectedCaptions
  leopardOrder?: LeopardOrder
  onSelectCaption?: (caption: Caption) => void
}) {
  const inputFile = leopardOrder?.extra?.imageCaptions?.inputFiles?.find(
    (i) => i.metaData?.fileName === caption?.image_name
  )

  const isLoading = !caption
  const captionText = caption?.caption_text || ''
  const checked = !!selectedCaptions?.[caption?.image_name || '']?.checked

  if (isLoading) return null

  return (
    <Box key={index} multiline fullWidth>
      <Box fullWidth direction="row">
        <Box style={{ minWidth: FIGMA_IMAGE_WIDTH, maxWidth: FIGMA_IMAGE_WIDTH }} position="relative">
          <Box position="absolute" visibility={enableCheckbox && captionText ? 'visible' : 'hidden'}>
            <Box onClick={() => onSelectCaption?.(caption)} pointer backgroundColor="white">
              <Checkbox value={checked} />
            </Box>
          </Box>
          <VitecImage vitecImage={inputFile?.metaData as UploadedFileInfoMetadata} />
        </Box>
        <Box left>
          <Box style={{ height: 80 }} fullWidth>
            {!captionText && <Loader size={15} color="primary" />}
            {captionText && <FigmaText textKey={Texts.ImageTextsItCssImageText} text={captionText} />}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
