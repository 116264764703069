import * as ReactRouterDom from 'react-router-dom'
import { addQueryParamsToUrl, parseQueryParam } from './useQueryparamHelper'

export const useNavigate = (opts: { enablePreserveQueryParams?: boolean; target?: '_blank' } = {}) => {
  const { enablePreserveQueryParams = true } = opts
  const navigate = ReactRouterDom.useNavigate()

  return <T>(path: string, queryParams?: T) => {
    const search = enablePreserveQueryParams ? window.location.search.replace('?', '') : ''
    const searchQueryParams = parseQueryParam<T>(search)

    const qps = { ...searchQueryParams, ...queryParams }
    if (opts.target === '_blank') window.open(addQueryParamsToUrl(path, qps), opts.target)
    else navigate(addQueryParamsToUrl(path, qps))
  }
}
