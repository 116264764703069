import { smallSpacing } from '../enums/Spacings.js'
import { VitecNextProductInfoItem } from '../types/VitecNextProductTypes.js'
import Box from './Box.js'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems.js'

import dayjs from 'dayjs'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct.js'
import { useVitecNOOrder } from '../hooks/QueryHooks.js'
import { useAreGazelleProductsEnabled, useIsProductEnabled } from '../hooks/useIsProductEnabled.js'
import { VitecNextOrderItemButton } from './VitecNextOrderItemButton.js'

type VitecNextOrderItemProps = {
  vitecNextProduct: VitecNextProduct
  vitecNextOrderId: string
}

export const DashboardListItemDetailsEmptyState = (props: VitecNextOrderItemProps) => {
  const { vitecNextProduct, vitecNextOrderId } = props
  const vitecNextProductInfoItem: VitecNextProductInfoItem = VitecNextProductInfoItems[vitecNextProduct]
  const { buttons } = vitecNextProductInfoItem

  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)

  const { data: isProductEnabled, isLoading: isLoadingProduct } = useIsProductEnabled(props)

  if (!vitecNextOrder) return null

  return (
    <Box fullWidth align="center">
      <Box>
        <Box pointer alignSelf="flex-start" direction="row" align="center" fullWidth>
          {buttons.map((button, i) => (
            <Box key={i} top spacing={smallSpacing}>
              <VitecNextOrderItemButton
                button={button}
                vitecNOOrder={vitecNextOrder}
                vitecNextProduct={vitecNextProduct}
                isProductEnabled={isProductEnabled}
                index={i}
                now={dayjs().format()}
                isLoading={isLoadingProduct}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
