import { SelfDeclarationItem } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'

export function formatSelfDeclarationItem(r: SelfDeclarationItem): { question: any; answer: any } {
  let question = cleanText(r.question)
  let answer = cleanText(r.answer)

  if (question.includes('- Ja')) {
    question = question.replace('- Ja', '')
    answer = `- Ja, ${answer}`
  }

  return { question, answer }
}

function cleanText(text: string): string {
  return text.replaceAll('◆', '-').replace(/\d+\./g, '').trim()
}
