import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useRef, useState } from 'react'
import { SuccessColor, Yellow25, Yellow50 } from '../../../../pure/libs/Colors'
import { YYYYMMDD_HHmm } from '../../../../pure/libs/Consts'
import { DescriptionTypes, DescriptionsFields } from '../../../../pure/libs/VitecNextOrderProductDescriptionsItemsPure'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { BorderRadixes } from '../enums/BorderRadixes'
import { minSpacing, smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { fulfillLeopardOrder } from '../libs/CloudFunctionsApiHandler'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import { HIGHLIGHTED_TOP_ABSOLUTE_POSITION } from '../libs/HardCodedSizes'
import Images from '../libs/Images'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { HardCodedTexts, Texts } from '../libs/Texts'
import { VitecNextOrderProductDescriptionsItems } from '../libs/VitecNextOrderProductDescriptionsItems'
import { onCopyText } from '../libs/onClickCopy'
import Box from './Box'
import { Checkbox } from './Checkbox'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import Loader from './Loader'
import Tooltip from './Tooltip'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'

export function VitecNextOrderProductDescriptionsItem({
  itemKey,
  fields,
  setFields,
  vitecNextOrder,
  enableUpload = true,
  leopardOrder
}: {
  itemKey: DescriptionTypes
  fields?: DescriptionsFields
  setFields: (fields: DescriptionsFields) => void
  enableUpload?: boolean
  vitecNextOrder: VitecNextOrder
  leopardOrder: LeopardOrder
}) {
  const ref = useRef<HTMLDivElement>(null)
  const { state } = useAppState()
  const { orderId: vitecNextOrderId } = vitecNextOrder
  const [loading, setLoading] = useState(false)

  const item = VitecNextOrderProductDescriptionsItems[itemKey]

  const { checked = true, fulfilledAt, isFulfilling, value } = fields?.[itemKey] || {}

  // if (!value) return <VitecNextOrderProductBasicAssociationItemEmptyState itemKey={itemKey} />

  const isLoading = loading || isFulfilling

  const onClickChecked = (checked: boolean, key: DescriptionTypes, fields: DescriptionsFields) =>
    setFields({ ...fields, [key]: { ...fields[key], checked, isFulfilling: false } })

  const onClickUpload = () =>
    Promise.resolve(setLoading(true))
      .then(() =>
        fulfillLeopardOrder(
          {
            vitecNextOrderId,
            vitecNextProduct: VitecNextProduct.DESCRIPTIONS_EXPERIMENTAL,
            descriptionsFulfillRequest: {
              key: itemKey
            }
          },
          state
        )
      )
      .catch(captureAndNotifyError)
      .finally(() => setLoading(false))

  return (
    <Container direction="row" fullWidth justify="space-between" position="relative">
      <Box ref={ref} style={{ position: 'absolute', top: -HIGHLIGHTED_TOP_ABSOLUTE_POSITION }} />
      <Box fullWidth direction="row">
        {fields && enableUpload && (
          <Box onClick={() => onClickChecked(!checked, itemKey, fields)} pointer top spacing={minSpacing}>
            <Checkbox value={checked} />
          </Box>
        )}
        <Box left spacing={smallSpacing} fullWidth>
          <FigmaText styleTextKey={HardCodedTexts.cssBaItemHeader} textKey={item.textKey} />
          <BackgroundTextContainer>
            <TextContainer>
              <FigmaTextWithLinebreaks textKey={Texts.BasicAssociationBaAnimal} text={value} />
            </TextContainer>
          </BackgroundTextContainer>
        </Box>
      </Box>
      <Box direction="row">
        {enableUpload && (
          <Box left spacing={smallSpacing} pointer>
            <Tooltip
              title={
                fulfilledAt
                  ? formatText(getFigmaText(Texts.standardCtaUploadedToNextAt), [
                      dayjs(fulfilledAt).format(YYYYMMDD_HHmm)
                    ])
                  : getFigmaText(Texts.standardCtaUploadToNext)
              }
              placement="top"
              backgroundColor={fulfilledAt ? SuccessColor : undefined}
              onClick={() => onClickUpload()}
            >
              <Box>
                {isLoading && <Loader size={Images.uploadArrow.width} />}
                {!isLoading && (
                  <FigmaImageContainer imageKey={fulfilledAt ? Images.uploadedArrow : Images.uploadArrow} />
                )}
              </Box>
            </Tooltip>
          </Box>
        )}
        <Box left spacing={smallSpacing} pointer onClick={() => onCopyText(value)}>
          <Tooltip title={getFigmaText(Texts.standardCtaCopyText)} placement="top">
            <FigmaImageContainer imageKey={Images.copyBlack} />
          </Tooltip>
        </Box>
      </Box>
    </Container>
  )
}

const Container = styled(Box)<{ isSelected?: boolean }>`
  background-color: ${({ isSelected }) => (isSelected ? Yellow50 : 'transparent')};
  border-radius: ${BorderRadixes.regular};
`

const BackgroundTextContainer = styled(Box)`
  background-color: white;
  border-radius: ${BorderRadixes.regular};
`

const TextContainer = styled(Box)<{ isSelected?: boolean }>`
  background-color: ${({ isSelected }) => (isSelected ? Yellow25 : 'transparent')};
  border-radius: ${BorderRadixes.regular};
`
