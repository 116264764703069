import { IsVitecNextProductEnabledResponse } from '../../../../pure/leopard/IsVitecNextProductEnabledResponse'
import { JaguarResultResponse } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { formatRoutePathVitecNextOrderProduct } from '../../../../pure/libs/RoutePath'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { smallSpacing } from '../enums/Spacings'
import { useIsProductEnabled } from '../hooks/useIsProductEnabled'
import { useJaguarResultsExperimental } from '../hooks/useJaguarResults'
import { useNavigate } from '../hooks/useNavigate'
import Images from '../libs/Images'
import { IsVitecNextProductEnabledInfo } from '../libs/IsVitecNextProductEnabledInfo'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { InfoIcon, VitecNextOrderLandingItemStatusPlayCircleIcon } from './Icons'
import { LoaderGreen } from './LoaderGreen'
import Tooltip from './Tooltip'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'

export function VitecNextOrderLandingItem({
  vitecNextProduct,
  vitecNextOrder,
  leopardOrder
}: {
  vitecNextProduct: VitecNextProduct
  vitecNextOrder: VitecNextOrder
  leopardOrder?: LeopardOrder
}) {
  const navigate = useNavigate()
  const vitecNextOrderId = vitecNextOrder?._id

  const { textKey } = VitecNextProductInfoItems[vitecNextProduct]

  const { data: isVitecNextProductEnabled } = useIsProductEnabled({ vitecNextOrderId, vitecNextProduct })

  const code = isVitecNextProductEnabled?.code
  const isWarning = !!code
  const warningTooltipTitle =
    (code &&
      IsVitecNextProductEnabledInfo[code].getText({ vitecNextOrder, isVitecNextProductEnabled, vitecNextProduct })) ||
    ''

  const { data } = useJaguarResultsExperimental<JaguarResultResponse>(vitecNextOrderId, vitecNextProduct)
  const errorToolTipTitle = data?.status === 'error' ? getFigmaText(Texts.standardNotificationCiError) : ''

  const tooltipText = errorToolTipTitle || warningTooltipTitle

  const isDone = data?.status === 'success'

  return (
    <Tooltip title={tooltipText} placement="left">
      <Box
        fullWidth
        direction="row"
        align="center"
        link={isDone}
        onClick={() => isDone && navigate(formatRoutePathVitecNextOrderProduct({ vitecNextOrderId, vitecNextProduct }))}
        pointer={!!tooltipText}
      >
        <Box>
          <VitecNextOrderLandingItemStatusIcon
            leopardOrder={leopardOrder}
            isProductEnabled={isVitecNextProductEnabled}
          />
        </Box>
        <Box left spacing={smallSpacing}>
          <FigmaText textKey={Texts.cssDashboardAllProgressProductName} text={getFigmaText(textKey)} />
        </Box>
        {!leopardOrder && isWarning && (
          <Box left spacing={smallSpacing}>
            <InfoIcon color="black" />
          </Box>
        )}
      </Box>
    </Tooltip>
  )
}

const VitecNextOrderLandingItemStatusIcon = ({
  leopardOrder,
  isProductEnabled,
  response
}: {
  leopardOrder?: LeopardOrder
  isProductEnabled?: IsVitecNextProductEnabledResponse
  response?: JaguarResultResponse
}) => {
  const code = isProductEnabled?.code

  switch (true) {
    case response?.status === 'success':
      return <FigmaImageContainer imageKey={Images.greenRoundedCheckmark} />
    case response?.status === 'error':
      return <FigmaImageContainer imageKey={Images.redErrorTriangle} />
    case !!code:
      return <FigmaImageContainer imageKey={Images.yellowWarningTriangle} />
    case response?.status === 'processing':
      return <LoaderGreen />
    default:
      return <VitecNextOrderLandingItemStatusPlayCircleIcon />
  }
}
