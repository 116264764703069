import { IsVitecNextProductEnabledResponseCode } from '../../../../pure/leopard/IsVitecNextProductEnabledResponse'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { smallSpacing } from '../enums/Spacings'
import { useLeopardOrder, useVitecNOOrder } from '../hooks/QueryHooks'
import { useJaguarResultsExperimental } from '../hooks/useJaguarResults'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import { VitecNextProductStatusProps } from '../types/VitecNextProductTypes'
import Box from './Box'
import { VitecNextOrderItemStatusFile } from './VitecNextOrderItemStatusFile'
import { VitecNextOrderItemStatusItem } from './VitecNextOrderItemStatusItem'
import { VitecNextOrderItemStatusView } from './VitecNextOrderItemStatusView'

export const VitecNextOrderItemStatus = (props: VitecNextProductStatusProps) => {
  const { isProductEnabled, vitecNextProduct, enableLoadingFilesWidget = true } = props
  const { data: vitecNextOrder } = useVitecNOOrder(props.vitecNextOrder._id)

  const { data: leopardOrder } = useLeopardOrder(
    getLeopardOrderId({ externalEstateId: vitecNextOrder?.estateId, product: vitecNextProduct })
  )

  const requestId = leopardOrder?.requestId
  const { data: results } = useJaguarResultsExperimental(props.vitecNextOrder._id, vitecNextProduct)

  if (!vitecNextOrder) return null

  // Deprecated use validation on VitecNextItem instead
  if (isProductEnabled?.code === IsVitecNextProductEnabledResponseCode.DOCUMENT_NOT_FOUND)
    return (
      <VitecNextOrderItemStatusView
        textKey={Texts.standardNotificationMissingDocument}
        type="warning"
        statusProps={props}
      />
    )

  // Deprecated use validation on VitecNextItem instead
  if (isProductEnabled?.code === IsVitecNextProductEnabledResponseCode.ADDRESS_NOT_FOUND)
    return (
      <VitecNextOrderItemStatusView
        textKey={Texts.AreaDescriptionMissingArea}
        type="warning"
        text={formatText(getFigmaText(Texts.AreaDescriptionMissingArea), [vitecNextOrder?.address || ''])}
        statusProps={props}
      />
    )

  const successText = !!leopardOrder?.fulfilledAt ? getFigmaText(Texts.standardNotificationUploadComplete) : undefined

  return (
    <Box fullWidth>
      {enableLoadingFilesWidget && <VitecNextOrderItemStatusFile {...props} />}
      <Box top={enableLoadingFilesWidget} spacing={smallSpacing} fullWidth>
        <VitecNextOrderItemStatusItem
          {...props}
          vitecNextOrder={vitecNextOrder}
          vitecNextProduct={vitecNextProduct}
          isProductEnabled={isProductEnabled}
          successText={successText}
          results={results}
        />
      </Box>
    </Box>
  )
}
