import styled from '@emotion/styled'
import Colors from '../../../../pure/libs/Colors'
import { ImageKey } from '../../../../pure/types/Antiloop'
import { tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import Images from '../libs/Images'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'

export type DashboardLayouts = 'grid' | 'list'

export function DashboardLayoutSelectionButton({ type }: { type: DashboardLayouts }) {
  const { state, setState } = useAppState()
  const info = DashboardLayoutSelectionInfo[type]
  const isSelected = state.dashboardLayout === type
  return (
    <Container
      onClick={() => setState({ ...state, dashboardLayout: type })}
      pointer
      fullPadding
      spacing={tinySpacing}
      fullHeight
      isSelected={isSelected}
    >
      <FigmaImageContainer imageKey={info.imageKey} />
    </Container>
  )
}

const DashboardLayoutSelectionInfo: {
  [property in DashboardLayouts]: {
    imageKey: ImageKey
  }
} = {
  grid: {
    imageKey: Images.dashboardLayoutSelectionGrid
  },
  list: {
    imageKey: Images.dashboardLayoutSelectionRows
  }
}

export const Container = styled(Box)<{ isSelected: boolean }>`
  border: 1px solid ${Colors.white};
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.5)};
`
