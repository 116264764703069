// @flow
import invariant from 'invariant'

type AsyncFunction<T> = () => Promise<T>

const Cache = {}

export const withMemoryCache = <T>(fn: AsyncFunction<T>, key: string): AsyncFunction<T> => {
  invariant(key, key)
  return () => {
    if (Cache[key]) return Promise.resolve(Cache[key])
    return fn().then((res) => {
      Cache[key] = res
      return res
    })
  }
}

export const getFromMemoryCache = <T>(key: string): T | undefined => Cache[key]
