/* eslint-disable prettier/prettier */
import AddCircle from '@mui/icons-material/AddCircle'
import AddToPhotos from '@mui/icons-material/AddToPhotos'
import _AnnouncementIcon from '@mui/icons-material/Announcement'
import ArrowBack from '@mui/icons-material/ArrowBack'
import ArrowDownward from '@mui/icons-material/ArrowDownward'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowUpward from '@mui/icons-material/ArrowUpward'
import Autorenew from '@mui/icons-material/Autorenew'
import PreviewIcon from '@mui/icons-material/Preview';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import Check from '@mui/icons-material/Check'
import MaterialCheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import MaterialCheckboxIcon from '@mui/icons-material/CheckBox'
import Clear from '@mui/icons-material/Clear'
import Close from '@mui/icons-material/Close'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DoneIcon from '@mui/icons-material/Done'
import Email from '@mui/icons-material/Email'
import Error from '@mui/icons-material/Error'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FileCopy from '@mui/icons-material/FileCopy'
import HouseIcon from '@mui/icons-material/House'
import _InfoIcon from '@mui/icons-material/InfoOutlined'
import InsertDriveFile from '@mui/icons-material/InsertDriveFile'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import _KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import MenuIcon from '@mui/icons-material/Menu'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MyLocation from '@mui/icons-material/MyLocation'
import PauseCircleIcon from '@mui/icons-material/PauseCircle'
import Person from '@mui/icons-material/Person'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import Refresh from '@mui/icons-material/Refresh'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import YouTubeIcon from '@mui/icons-material/YouTube'
import Colors from '../../../../pure/libs/Colors'
import { THREE_REM } from '../enums/Spacings'
import { MENU_ARROW_DOWN_SIZE } from '../libs/HardCodedSizes'
import Icon, { IconProps, DEFAULT_ICON_SIZE as _DEFAULT_ICON_SIZE } from './Icon'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

export const DEFAULT_ICON_SIZE = _DEFAULT_ICON_SIZE // 16
export const BIG_ICON_SIZE = 24
export const MEDIUM_ICON_SIZE = 20

export const HomeFormCTAArrow = (props: Partial<IconProps>) => (<Icon component={ArrowForwardIcon} size={BIG_ICON_SIZE} color={Colors.white} {...props} />)
export const MenuLoggedInMobileIcon = (props: Partial<IconProps>) => (<Icon component={MenuIcon} size={BIG_ICON_SIZE} color={Colors.black} {...props} />)
export const MenuArrowBack = (props: Partial<IconProps>) => (<Icon component={ArrowBack} size={BIG_ICON_SIZE} color={Colors.black} {...props} />)
export const MenuFAQ = (props: Partial<IconProps>) => (<Icon component={QuestionAnswerIcon} size={BIG_ICON_SIZE} color={Colors.black} {...props} />)
export const HomeFormButtonPause = (props: Partial<IconProps>) => (<Icon component={PauseCircleIcon} size={BIG_ICON_SIZE} color={Colors.white} {...props} />)
export const HomeFormButtonCopy = (props: Partial<IconProps>) => (<Icon component={ContentCopyIcon} size={BIG_ICON_SIZE} color={Colors.white} {...props} />)
export const ModalUpsellIcon = (props: Partial<IconProps>) => (<Icon component={FavoriteIcon} size={BIG_ICON_SIZE} color={Colors.black} {...props} />)
export const ThumbUp = (props: Partial<IconProps>) => (<Icon component={ThumbUpIcon} size={BIG_ICON_SIZE} color={Colors.secondary100} {...props} />)
export const ThumbDown = (props: Partial<IconProps>) => (<Icon component={ThumbDownIcon} size={BIG_ICON_SIZE} color={Colors.secondary100} {...props} />)
export const Done = (props: Partial<IconProps>) => (<Icon component={DoneIcon} color={Colors.secondary100} {...props} />)
export const ExpandMoreIcon = (props: Partial<IconProps>) => (<Icon color={Colors.neutral50} component={ExpandMore} size={BIG_ICON_SIZE} {...props} />)
export const ExpandLessIcon = (props: Partial<IconProps>) => (<Icon color={Colors.neutral50} component={ExpandLess} size={BIG_ICON_SIZE} {...props} />)
export const FooterLinkedIn = (props: Partial<IconProps>) => (<Icon color={Colors.white} component={LinkedInIcon} size={THREE_REM} {...props} />)
export const FooterYouTube = (props: Partial<IconProps>) => (<Icon color={Colors.white} component={YouTubeIcon} size={THREE_REM} {...props} />)
export const MyAdsRemoveIcon = (props: Partial<IconProps>) => (<Icon component={CancelRoundedIcon} color={Colors.neutral100} backgroundColor={Colors.white} size={BIG_ICON_SIZE} {...props} />)
export const OnboardingArrowLeftIcon = (props: Partial<IconProps>) => (<Icon component={_KeyboardArrowLeftIcon} size={MENU_ARROW_DOWN_SIZE} color={Colors.black} {...props} />)
export const PersonIcon = (props: Partial<IconProps>) => (<Icon component={Person} size={BIG_ICON_SIZE} backgroundColor={Colors.neutral40} color={Colors.white} {...props} />)
export const NotificationCheckIcon = (props: Partial<IconProps>) => (<Icon size={BIG_ICON_SIZE} backgroundColor={Colors.primary100} color={Colors.white} component={Check} {...props} />)
export const ClearIcon = (props: Partial<IconProps>) => (<Icon color={Colors.black} component={Clear} {...props} />)
export const NotificationErrorIcon = (props: Partial<IconProps>) => (<Icon size={BIG_ICON_SIZE} backgroundColor={Colors.error100} color={Colors.white} component={Error} {...props} />)
export const PersonAdd = (props: Partial<IconProps>) => (<Icon component={PersonAddAltIcon} color={Colors.black} size={BIG_ICON_SIZE} {...props} />)
export const InfoIcon = (props: Partial<IconProps>) => (<Icon component={_InfoIcon} color={Colors.secondary100} {...props} />)
export const MenuArrowDownward = (props: Partial<IconProps>) => (<Icon component={ArrowDownward} color={Colors.primary40} backgroundColor={Colors.white} {...props} />)
export const MenuArrowUpward = (props: Partial<IconProps>) => (<Icon component={ArrowUpward} color={Colors.primary40} backgroundColor={Colors.white} {...props} />)
export const MoreHoriz = (props: Partial<IconProps>) => (<Icon size={20} component={MoreHorizIcon} backgroundColor={Colors.white} color={Colors.black} {...props} />)
export const MoreVert = (props: Partial<IconProps>) => (<Icon component={MoreVertIcon} color={Colors.neutral50} size={BIG_ICON_SIZE} {...props} />)
export const MoreVertWhite = (props: Partial<IconProps>) => (<Icon component={MoreVertIcon} color={Colors.white} size={BIG_ICON_SIZE} {...props} />)
export const AnnouncementIcon = (props: Partial<IconProps>) => (<Icon component={_AnnouncementIcon} backgroundColor={Colors.white} color={Colors.error100} size={BIG_ICON_SIZE} {...props} />)
export const HomeFormCheckIcon = (props: Partial<IconProps>) => (<Icon size={BIG_ICON_SIZE} color={Colors.secondary100} component={Check} {...props} />)
export const ModalCloseIcon = (props: Partial<IconProps>) => (<Icon size={BIG_ICON_SIZE} color={Colors.primary100} component={Close} {...props} />)
export const UploadItemCloseIcon = (props: Partial<IconProps>) => (<Icon color={Colors.primary100} component={Close} {...props} />)
export const CreateAdFinprintInfoIcon = (props: Partial<IconProps>) => (<Icon component={_InfoIcon} color={Colors.white} backgroundColor={Colors.black} {...props} />)
export const AdCreatorCTACheckIcon = (props: Partial<IconProps>) => (<Icon size={BIG_ICON_SIZE} color={Colors.white} component={Check} {...props} />)
export const AdCreatorFileCopy = (props: Partial<IconProps>) => (<Icon color={Colors.primary60} component={FileCopy} {...props} />)
export const AdCreatorLatestAdsFileCopy = (props: Partial<IconProps>) => (<Icon color={Colors.primary100} component={FileCopy} {...props} />)
export const AdCreatorRegenerate = (props: Partial<IconProps>) => (<Icon color={Colors.primary60} component={Autorenew} {...props} />)
export const AdCreatorCreateNewAdCircle = (props: Partial<IconProps>) => (<Icon color={Colors.white} component={AddCircle} {...props} />)
export const CollapseIcon = (props: Partial<IconProps>) => (<Icon component={KeyboardArrowDownIcon} color={Colors.primary100}  {...props} />)
export const ExpandIcon = (props: Partial<IconProps>) => (<Icon component={KeyboardArrowUpIcon} color={Colors.primary100}  {...props} />)
export const LatestAdsRemoveIcon = (props: Partial<IconProps>) => (<Icon component={CancelRoundedIcon} color={Colors.neutral100} backgroundColor={Colors.white} {...props} />)
export const UploadCloudUploadIcon = (props: Partial<IconProps>) => (<Icon component={CloudUploadIcon} size={BIG_ICON_SIZE} color={Colors.white} {...props} />)
export const AdCreatorKeyboardArrowRightIcon = (props: Partial<IconProps>) => (<Icon component={KeyboardArrowRightIcon} size={BIG_ICON_SIZE} color={Colors.white} {...props} />)
export const AdCreatorAdEmailIcon = (props: Partial<IconProps>) => (<Icon component={Email} size={BIG_ICON_SIZE} color={Colors.primary60} {...props} />)
export const PricingRowCheckIcon = (props: Partial<IconProps>) => (<Icon component={Check}  color={Colors.black} {...props} />)

export const AdCreatorLocationIcon = (props: Partial<IconProps>) => (<Icon component={MyLocation} color={Colors.primary100}  {...props} />)
export const AdCreatorAddKeyWordsIcon = (props: Partial<IconProps>) => (<Icon component={AddToPhotos} color={Colors.primary100}  {...props} />)
export const AdCreatorArrowDownIcon = (props: Partial<IconProps>) => (<Icon component={KeyboardArrowDownIcon} color={Colors.primary100}  {...props} />)
export const AdCreatorArrowUpIcon = (props: Partial<IconProps>) => (<Icon component={KeyboardArrowUpIcon} color={Colors.primary100}  {...props} />)
export const AdCreatorHouseIcon = (props: Partial<IconProps>) => (<Icon component={HouseIcon} color={Colors.primary100}  {...props} />)
export const AdCreatorAudienceIcon = (props: Partial<IconProps>) => (<Icon component={Person} color={Colors.primary100}  {...props} />)
export const AdCreatorFormSectionKeyWordDeleteIcon = (props: Partial<IconProps>) => (<Icon component={Close} color={Colors.primary100}  {...props} />)
export const UploadLoadingCheckIcon = (props: Partial<IconProps>) => (<Icon component={Check} color={Colors.success100} {...props} />)
export const KeyWordsDrawerInfoIcon = (props: Partial<IconProps>) => (<Icon component={ErrorOutline} color={Colors.secondary100} {...props} />)
export const CheckBoxOutlineBlank = (props: Partial<IconProps>) => (<Icon component={MaterialCheckBoxOutlineBlankIcon} color={Colors.black} {...props} />)

export const VitecSummarizeDocFileIcon = (props: Partial<IconProps>) => (<Icon component={InsertDriveFile}  color={Colors.primary100} {...props} />)

export const VitecNextOrderRefreshIcon = (props: Partial<IconProps>) => (<Icon size={BIG_ICON_SIZE} color={"grey"} component={Refresh} {...props} />)
export const VitecNextOrderArrowBackIcon = (props: Partial<IconProps>) => (<Icon component={ArrowBack} color={Colors.white} size={BIG_ICON_SIZE} {...props} />)

export const CheckBoxIcon = (props: Partial<IconProps>) => (<Icon component={MaterialCheckboxIcon} color={Colors.white} backgroundColor={Colors.black} borderRadius={"0px"} {...props} />)
export const CheckBoxBlankIcon = (props: Partial<IconProps>) => (<Icon component={MaterialCheckBoxOutlineBlankIcon} backgroundColor={Colors.white} color={Colors.black} {...props} />)

export const Preview = (props: Partial<IconProps>) => (<Icon component={PreviewIcon} color={Colors.primary100} {...props} />)

export const VitecNextOrderLandingItemStatusPlayCircleIcon = (props: Partial<IconProps>) => (<Icon component={PlayCircleOutlineIcon} color={Colors.neutral60} {...props}  />)
export const DashboardStatusItemViewPopperRowForwardArrow = (props: Partial<IconProps>) => (<Icon component={ArrowForwardIcon} size={DEFAULT_ICON_SIZE} color={Colors.white} {...props} />)
export const DashboardStatusItemViewPopperRowForwardArrowBlack = (props: Partial<IconProps>) => (<Icon component={ArrowForwardIcon} size={DEFAULT_ICON_SIZE} color={Colors.black} {...props} />)

export const AdCreatorLatestadsSectionCollapseIcon = CollapseIcon
export const AdCreatorLatestadsSectionExpandIcon = ExpandIcon
export const AdCreatorMoreOptionsButtonCollpasedIcon = CollapseIcon
export const AdCreatorMoreOptionsButtonExpandedIcon = ExpandIcon
