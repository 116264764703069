import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { DescriptionsFields } from '../../../../pure/libs/VitecNextOrderProductDescriptionsItemsPure'
import { getLeopardOrderIdForOrder } from '../../../../pure/libs/getLeopardOrderId'
import { upsertLeopardOrder } from '../libs/DBApiHandler'
import { useLeopardOrder, useVitecNOOrder } from './QueryHooks'

export const useDescriptionsFieldsState = (vitecNextOrderId: string) => {
  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)

  const { data: leopardOrder } = useLeopardOrder(
    getLeopardOrderIdForOrder({ vitecNextProduct: VitecNextProduct.DESCRIPTIONS_EXPERIMENTAL, vitecNextOrder })
  )

  const defaultFields: DescriptionsFields = {
    short_description: {
      value: leopardOrder?.extra?.descriptions?.short_description || '',
      key: 'short_description'
    },
    long_description: {
      value: leopardOrder?.extra?.descriptions?.long_description || '',
      key: 'long_description'
    },
    innhold: {
      value: leopardOrder?.extra?.descriptions?.innhold || '',
      key: 'innhold'
    }
  }

  const fields = leopardOrder?.extra?.descriptionsFields || defaultFields

  const setFields = (fields: DescriptionsFields) =>
    leopardOrder &&
    upsertLeopardOrder({ ...leopardOrder, extra: { ...leopardOrder?.extra, descriptionsFields: fields } })

  return { fields, setFields }
}
