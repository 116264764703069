import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { useNow } from '../hooks/useNow'
import Box from './Box'
import { VitecNextOrderItem } from './VitecNextOrderItem'

export function DashboardGrid({ vitecNextOrder }: { vitecNextOrder; now: string }) {
  const now = useNow()

  return (
    <Box fullWidth>
      <Box direction="row" fullHeight fullWidth justify="space-between">
        {[
          VitecNextProduct.IMAGE_CAPTIONS_EXPERIMENTAL,
          VitecNextProduct.CONSTRUCTION_INFO_EXPERIMENTAL,
          VitecNextProduct.DESCRIPTIONS_EXPERIMENTAL
        ]
          .filter((v) => !!v)
          .map((vitecNextProduct, i) => (
            <VitecNextOrderItem
              key={i}
              vitecNextOrder={vitecNextOrder}
              vitecNextProduct={vitecNextProduct as VitecNextProduct}
              now={now}
            />
          ))}
      </Box>
      <Box direction="row" fullHeight top fullWidth justify="space-between">
        {[
          VitecNextProduct.BASIC_ASSOCIATION_EXPERIMENTAL,
          VitecNextProduct.SELF_DECLERATION_EXPERIMENTAL,
          VitecNextProduct.AREA_DESCRIPTION_EXPERIMENTAL
        ]
          .filter((v) => !!v)
          .map((vitecNextProduct, i) => (
            <VitecNextOrderItem
              key={i}
              vitecNextOrder={vitecNextOrder}
              vitecNextProduct={vitecNextProduct as VitecNextProduct}
              now={now}
            />
          ))}
      </Box>
    </Box>
  )
}
