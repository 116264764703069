import React, { Dispatch, SetStateAction, useContext } from 'react'
import { FirebaseUser } from '../../../../pure/types/types'
import { DrawerContext, DrawerTypes } from '../components/useDrawerContext'
import useFirebaseUser from '../components/useFirebaseUser'
import { StorageKey, StorageType } from './useLocalStorageItemHelper'
import { useStorageItem } from './useStorageItem'

export type State = {
  redirectUrl: string
  firebaseUser?: FirebaseUser
  drawerContexts: { [property in DrawerTypes]: DrawerContext }
}

export const DEFAULT_STATE: State = {
  redirectUrl: '',
  firebaseUser: undefined,
  drawerContexts: {} as State['drawerContexts']
}

export type AppContext = {
  state: State
  setState: Dispatch<SetStateAction<State>>
}
export const AppStateContext = React.createContext<AppContext>({
  state: DEFAULT_STATE,
  setState: () => ({})
})

const IGNORED_KEYS: Array<keyof State> = ['drawerContexts']

export const useAppStateContext = () => {
  const { storageItem: state, setStorageItem: setState } = useStorageItem(StorageKey.STATE, DEFAULT_STATE, {
    storageType: StorageType.SESSION_STORAGE,
    ignoredKeys: IGNORED_KEYS
  })

  return { state, setState }
}

export default function useAppState() {
  return useContext(AppStateContext)
}

export const useUserId = () => useFirebaseUser().data?.uid
