import invariant from 'invariant'
import {
  IsVitecNextProductEnabledResponse,
  IsVitecNextProductEnabledResponseCode
} from '../../../../pure/leopard/IsVitecNextProductEnabledResponse'
import {
  AreaDescriptionResultsResponse,
  ConstructionMethodResultsResponse,
  DescriptionsResultsResponse,
  SelfDeclarationResultsResponse
} from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import {
  getValidatableBasicAssociationEntriesEntries,
  isValidBasicAssociationValue
} from '../../../../pure/libs/AdValidationHelper'
import { createUniqueId } from '../../../../pure/libs/Common'
import { FREMTIND_EX_PDF_URL, MAX_NUMBER_OF_IMAGES } from '../../../../pure/libs/Consts'
import { formatRoutePathVitecNextOrderProduct } from '../../../../pure/libs/RoutePath'
import {
  BasicAssociationResponseItemsKey,
  VitecNextOrderProductBasicAssociationItemsPure
} from '../../../../pure/libs/VitecNextOrderProductBasicAssociationItemsPure'
import { pushHighlitedHistoryState } from '../libs/HistoryStateHelper'
import Images from '../libs/Images'
import { IsVitecNextProductEnabledInfo } from '../libs/IsVitecNextProductEnabledInfo'
import { createLeopardOrder } from '../libs/ProcessHandler'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { HardCodedTexts, Texts } from '../libs/Texts'
import { VitecNextOrderProductBasicAssociationItems } from '../libs/VitecNextOrderProductBasicAssociationItems'
import { VitecNextProductInfoItem, VitecNextProductInfoItemValidationItem } from '../types/VitecNextProductTypes'
import Box from './Box'
import { DashboardListItemDetailsBasicAssociationComponent } from './DashboardListItemDetailsBasicAssociationComponent'
import { DashboardListItemDetailsImageCaptionsComponent } from './DashboardListItemDetailsImageCaptionsComponent'
import { DashboardListItemDetailsSelfDeclarationComponent } from './DashboardListItemDetailsSelfDeclarationComponent'
import FigmaText from './FigmaText'
import { VitecNextOrderProductAreaDescriptionExperimental } from './VitecNextOrderProductAreaDescriptionExperimental'
import { VitecNextOrderProductBasicAssociationExperimental } from './VitecNextOrderProductBasicAssociationExperimental'
import { VitecNextOrderProductContructionInfoExperimental } from './VitecNextOrderProductContructionInfoExperimental'
import { VitecNextOrderProductDescriptionsExperimental } from './VitecNextOrderProductDescriptionsExperimental'
import { VitecNextOrderProductFeedbackViewExperimental } from './VitecNextOrderProductFeedbackViewExperimental'
import { VitecNextOrderProductImageCaptionsExperimental } from './VitecNextOrderProductImageCaptionsExperimental'
import { VitecNextOrderProductSelfDeclarationExperimental } from './VitecNextOrderProductSelfDeclarationExperimental'
import { formatSelfDeclarationItem } from '../libs/SelfDeclarationHelper'

export const VitecNextProductInfoItems: {
  [property in VitecNextProduct]: VitecNextProductInfoItem
} = {
  [VitecNextProduct.IMAGE_CAPTIONS_EXPERIMENTAL]: {
    textKey: Texts.dashboardAutoCaptioningTitleCaptioningTitleg,
    descriptionKey: Texts.dashboardAutoCaptioningDescr,
    imageKey: Images.imageCaptionIcon,
    slug: 'bildetekst',
    PreviewComponent: VitecNextOrderProductImageCaptionsExperimental,
    // StatusComponent: VitecNextOrderItemStatusImageCaptionsDeprecated,
    DashboardListItemDetailsComponent: DashboardListItemDetailsImageCaptionsComponent,
    buttons: [
      {
        buttonTextKey: Texts.ImageTextsCtaGenImageTexts,
        getButtonText: (r?: IsVitecNextProductEnabledResponse) =>
          formatText(getFigmaText(Texts.ImageTextsCtaGenImageTexts), [r?.noOfDocuments || '0']),
        onClick: (o) => createLeopardOrder(o)
      }
    ],
    validate: ({ isVitecNextProductEnabled, vitecNextProduct }) => {
      const header = getFigmaText(VitecNextProductInfoItems[vitecNextProduct].textKey)
      const { noOfDocuments } = isVitecNextProductEnabled || {}
      if (noOfDocuments === 0)
        return [
          {
            id: createUniqueId(),
            type: 'warning',
            header,
            message: getFigmaText(Texts.ImageTextsNotificationItNoImgages),
            onClick: undefined
          }
        ]

      if (noOfDocuments && noOfDocuments > MAX_NUMBER_OF_IMAGES)
        return [
          {
            id: createUniqueId(),
            type: 'warning',
            header,
            message: formatText(getFigmaText(Texts.ImageTextsErrorImageTextsToMany), [noOfDocuments]),
            onClick: undefined
          }
        ]

      return []
    },
    getSuccessText: ({ isVitecNextProductEnabled: r }) => {
      if (!r) return ''
      return formatText(getFigmaText(Texts.ImageTextsNotificationItCompleted), [r.noOfDocuments])
    }
  },
  [VitecNextProduct.CONSTRUCTION_INFO_EXPERIMENTAL]: {
    textKey: Texts.dashboardConstructionInfoTitle,
    descriptionKey: Texts.dashboardConstructionInfoDescr,
    imageKey: Images.constructionInfoIcon,
    slug: 'byggemate',
    PreviewComponent: VitecNextOrderProductContructionInfoExperimental,
    enableFulfillment: true,
    buttons: [
      {
        buttonTextKey: Texts.dashboardCtaGenerate,
        onClick: (o) => createLeopardOrder(o)
      }
    ],
    getText: ({ jaguarResults }) =>
      (jaguarResults as ConstructionMethodResultsResponse)?.text_outputs?.constructionMethod,
    validate: ({ isVitecNextProductEnabled, vitecNextProduct }) => {
      const header = getFigmaText(VitecNextProductInfoItems[vitecNextProduct].textKey)
      const { code } = isVitecNextProductEnabled || {}
      if (code === IsVitecNextProductEnabledResponseCode.DOCUMENT_NOT_FOUND)
        return [
          {
            id: createUniqueId(),
            type: 'warning',
            header,
            message: getFigmaText(Texts.ConstructionNotificationCiMissingDocument),
            onClick: undefined
          }
        ]

      return []
    }
  },
  [VitecNextProduct.BASIC_ASSOCIATION_EXPERIMENTAL]: {
    textKey: Texts.dashboardBasicAssociationTitle,
    descriptionKey: Texts.dashboardBasicAssociationDesc,
    imageKey: Images.basicAssiciationIcon,
    slug: 'basic-association',
    PreviewComponent: VitecNextOrderProductBasicAssociationExperimental,
    DashboardListItemDetailsComponent: DashboardListItemDetailsBasicAssociationComponent,
    enableDiffInFeedbackView: false,
    buttons: [
      {
        buttonTextKey: Texts.dashboardCtaGenerate,
        onClick: createLeopardOrder
      }
    ],
    getText: ({ leopardOrder }) => {
      const basicFields = Object.entries(leopardOrder?.extra?.basicAssociationFields || {}).reduce((a, [k, v]) => {
        return {
          ...a,
          [k]: {
            ...v,
            fulfilledAt: undefined,
            isFulfilling: undefined,
            checked: undefined,
            key: undefined
          }
        }
      }, {})

      return JSON.stringify(basicFields, null, 2)
    },
    validate: (props) => {
      const { leopardOrder, estate, navigate, vitecNextProduct, vitecNextOrder, drawerContext } = props
      const vitecNextOrderId = vitecNextOrder?.orderId
      const fields = leopardOrder?.extra?.basicAssociationFields

      if (!fields) return []

      const entries = Object.entries(VitecNextOrderProductBasicAssociationItemsPure)
        // TODO WRITE TEST, onlny allow the ones that are enabled
        .filter(([k]) => VitecNextOrderProductBasicAssociationItemsPure[k].enabled !== false)

      const missingFields: VitecNextProductInfoItemValidationItem[] = entries
        .filter(([k, v]) => v.getValueFromEstate && !fields?.[k]?.value)
        // TODO WRITE TEST, should not fail missingFIelds test for fields that does not exist
        .filter(([k, v]) => VitecNextOrderProductBasicAssociationItems[k])
        .map(([k, v]) => {
          const infoItem = VitecNextOrderProductBasicAssociationItems[k]

          invariant(infoItem, `Cannot find infoItem for key ${k}`)
          invariant(vitecNextOrderId, `!vitecNextOrderId`)
          // TODO WRITE TEST, should warn about missing fields
          return {
            id: fields?.[k]?.id,
            type: 'warning',
            header: getFigmaText(infoItem.textKey),
            message: formatText(getFigmaText(Texts.componentsValidationMessagesDocumentNotFound), [
              getFigmaText(infoItem.textKey)
            ]),
            onClick: undefined, //() => navigate(formatRoutePathVitecNextOrderProduct({ vitecNextProduct, vitecNextOrderId })),
            ctaText: getFigmaText(Texts.SelfDeclerationSeeReference)
          }
        })

      const invalidFields: VitecNextProductInfoItemValidationItem[] = getValidatableBasicAssociationEntriesEntries({
        estate,
        fields
      })
        // TODO WRITE TEST, should not fail validation test for fields that does not exist
        .filter(([k, v]) => VitecNextOrderProductBasicAssociationItems[k])
        .map(([k, v]) => {
          const messageText = getFigmaText(Texts.dashboardDashboardListValidationMessageText)
          const infoItem = VitecNextOrderProductBasicAssociationItems[k]
          const estateValue = v.getValueFromEstate?.(estate)?.toString()
          const fieldValue = fields?.[k]?.value?.toString()

          const isValid = isValidBasicAssociationValue({
            fields,
            key: k as BasicAssociationResponseItemsKey,
            fieldTemplate: v,
            estate
          })

          invariant(estateValue, `!estateValue`)
          invariant(fieldValue, `!fieldValue`)
          invariant(infoItem, `Cannot find infoItem for key ${k}`)
          invariant(vitecNextOrderId, `!vitecNextOrderId`)

          const fieldFileName = fields?.[k]?.fileName || 'Doc'
          const id = fields?.[k]?.id
          return {
            id,
            type: isValid ? 'success' : 'error',
            message: `${messageText} Next: ${estateValue}\n${messageText} ${fieldFileName}: ${fieldValue}`,
            header: getFigmaText(infoItem.textKey),
            onClick: () => {
              drawerContext.highlight(fields?.[k])
              pushHighlitedHistoryState({ vitecNextOrderId, highlightedId: id })
            }
          }
        })

      return missingFields.concat(invalidFields)
    }
  },
  [VitecNextProduct.SELF_DECLERATION_EXPERIMENTAL]: {
    textKey: Texts.SelfDeclerationSdOrderItemHeader,
    descriptionKey: Texts.SelfDeclerationSdOrderItemDescrition,
    DescriptionSuffixComponent: () => (
      <Box link>
        <FigmaText
          textKey={Texts.SelfDeclerationSdOrderItemDescrition}
          text="Ex link"
          anchorHref={FREMTIND_EX_PDF_URL}
        />
      </Box>
    ),
    imageKey: Images.adValidationIcon,
    slug: 'selfdecleration',
    PreviewComponent: VitecNextOrderProductSelfDeclarationExperimental,
    DashboardListItemDetailsComponent: DashboardListItemDetailsSelfDeclarationComponent,
    enableFulfillment: false,
    enableDiffInFeedbackView: false,
    buttons: [
      {
        buttonTextKey: Texts.dashboardCtaGenerate,
        onClick: (o) => createLeopardOrder(o)
      }
    ],
    getText: ({ jaguarResults }) => {
      if (!jaguarResults) return ''
      const selfDeclerationResults = jaguarResults as SelfDeclarationResultsResponse

      // TODO FIX: below code is duplicated in VitecNextOrderProductSelfDeclaration
      return (
        selfDeclerationResults.structured_outputs?.items
          .filter((r) => r.answer)
          .sort((a, b) => parseInt(a.question_number) - parseInt(b.question_number))
          .map((r) => formatSelfDeclarationItem(r))
          .join('\n\n') || ''
      )
    }
  },
  [VitecNextProduct.AREA_DESCRIPTION_EXPERIMENTAL]: {
    textKey: Texts.dashboardAreaDescriptionTItle,
    descriptionKey: Texts.dashboardAreaDescriptionDescr,
    imageKey: Images.areaDescriptionIcon,
    slug: 'descriptions',
    PreviewComponent: VitecNextOrderProductAreaDescriptionExperimental,
    enableFulfillment: false,
    enableDiffInFeedbackView: false,
    buttons: [
      {
        buttonTextKey: Texts.dashboardCtaGenerate,
        onClick: (o) => createLeopardOrder(o)
      }
    ],
    getText: ({ jaguarResults }) =>
      (jaguarResults as AreaDescriptionResultsResponse)?.structured_outputs?.areaDescription || ''
  },
  [VitecNextProduct.DESCRIPTIONS_EXPERIMENTAL]: {
    textKey: HardCodedTexts.DescriptionsDOrderItemHeader,
    descriptionKey: Texts.DescriptionsDOrderItemDescription,
    imageKey: Images.standardIIcon,
    slug: 'descriptions',
    PreviewComponent: VitecNextOrderProductDescriptionsExperimental,
    enableFulfillment: false,
    getText: ({ jaguarResults }) => {
      const results = jaguarResults as DescriptionsResultsResponse
      return `<b>${results?.text_outputs?.short_description}</b>\n\n${results?.text_outputs?.long_description}\n\n<b>Innhold</b>\n\n${results?.text_outputs?.innhold}`
    },
    buttons: [
      {
        buttonTextKey: Texts.ImageTextsSelectPinpointText,
        onClick: (props) =>
          createLeopardOrder({
            ...props,
            extra: {
              ...props.extra,
              longShortDescription: {
                longShortDescriptionType: 'bullets'
              }
            }
          })
      },
      {
        buttonTextKey: Texts.ImageTextsSelectFloatingText,
        onClick: (props) =>
          createLeopardOrder({
            ...props,
            extra: { ...props.extra, longShortDescription: { longShortDescriptionType: 'long' } }
          })
      }
    ]
  }
}

export const validateVitecNextProductInfoItem: VitecNextProductInfoItem['validate'] = (props) => {
  const { isVitecNextProductEnabled, leopardOrder, vitecNextProduct } = props
  const { code } = isVitecNextProductEnabled || {}
  if (!!leopardOrder?.finishedAt) return []
  const header = getFigmaText(VitecNextProductInfoItems[vitecNextProduct].textKey)

  if (!!code)
    return [
      {
        id: createUniqueId(),
        type: 'warning',
        header,
        message: IsVitecNextProductEnabledInfo[code].getText(props),
        onClick: undefined
      }
    ]

  return []
}
