import React from 'react'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { shouldShowLongWaitMessage } from '../../../../pure/leopard/VitecNextProductPureInfoItems'
import { createUniqueId } from '../../../../pure/libs/Common'
import { smallSpacing } from '../enums/Spacings'
import { useLeopardOrder, useVitecNOOrder } from '../hooks/QueryHooks'
import useAppState from '../hooks/useAppState'
import useAppStateSession from '../hooks/useAppStateSession'
import { useJaguarResultsExperimental } from '../hooks/useJaguarResults'
import Images from '../libs/Images'
import { createLeopardOrder } from '../libs/ProcessHandler'
import { Texts } from '../libs/Texts'
import { onCopyText } from '../libs/onClickCopy'
import Box from './Box'
import Button from './Button'
import ProgressBar from './ProgressBar'
import { BetaButton } from './VitecNextOrderItemIconView'
import { VitecNextOrderItemLongWaitFigmaText } from './VitecNextOrderItemLongWaitFigmaText'
import { VitecNextOrderProductButtonsFulfill } from './VitecNextOrderProductButtonsFulfill'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'

export type Props = {
  vitecNextOrderId: string
  vitecNextProduct: VitecNextProduct
  SuffixComponent?: React.FC
  fulfillMentSuccessText?: string
  enableCopy?: boolean
  enableRegenerate?: boolean
  onPreFulfill?: () => void
  onPostFulfill?: () => void
}

export function VitecNextOrderProductButtons(props: Props) {
  const {
    vitecNextOrderId,
    vitecNextProduct,
    SuffixComponent,
    fulfillMentSuccessText,
    enableCopy = true,
    enableRegenerate = true
  } = props

  const { enableFulfillment = true } = VitecNextProductInfoItems[vitecNextProduct]
  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)

  const { data: leopardOrder } = useLeopardOrder(
    getLeopardOrderId({ externalEstateId: vitecNextOrder?.estateId, product: vitecNextProduct })
  )

  const {
    state: { enablePreview = true }
  } = useAppStateSession()

  const [requestId, setRequestId] = React.useState(leopardOrder?.requestId)

  const { data: jaguarResults } = useJaguarResultsExperimental(vitecNextOrderId, vitecNextProduct)

  const context = useAppState()
  const { state } = context
  const [isRegenerating, setisRegenerating] = React.useState(false)
  const isLoading = isRegenerating
  const { getText, enableBetaUX } = VitecNextProductInfoItems[vitecNextProduct]

  if (!leopardOrder) return null

  const onClickCreateLeopardOrder = () => {
    const requestId = createUniqueId()
    setRequestId(requestId)
    Promise.resolve(setisRegenerating(true))
      .then(
        () =>
          vitecNextOrder &&
          createLeopardOrder({
            vitecNextOrder,
            vitecNextProduct,
            state,
            requestId,
            enablePreview: enablePreview || false
          })
      )
      .then(() => {
        setisRegenerating(false)
        window.location.reload() // Hi future dev, for some reason basicAssociation needs this in order to show the new data, i also fear that other products are showing stale data, good luck!
      })
  }

  if (isRegenerating)
    return (
      <Box fullWidth fullPadding>
        <ProgressBar />
        {shouldShowLongWaitMessage({ results: jaguarResults, vitecNextProduct }) && (
          <Box top spacing={smallSpacing}>
            <VitecNextOrderItemLongWaitFigmaText vitecNextOrder={vitecNextOrder} />
          </Box>
        )}
      </Box>
    )

  return (
    <Box fullWidth direction="row" justify="space-between" align="center">
      <Box fullWidth direction="row" align="center">
        <VitecNextOrderProductButtonsFulfill
          fulfillMentSuccessText={fulfillMentSuccessText}
          disabled={!enableFulfillment || isRegenerating}
          vitecNextOrderId={vitecNextOrderId}
          vitecNextProduct={vitecNextProduct}
          onPreFulfillment={props.onPreFulfill}
        />
        {enableRegenerate && (
          <Box left>
            <Button
              textKey={Texts.standardCtaRegenerate}
              spacing={smallSpacing}
              onClick={onClickCreateLeopardOrder}
              disabled={isLoading}
              imageKey={Images.regenerateText}
              loading={
                isRegenerating &&
                jaguarResults?.status !==
                  'processing' /** There is another spinner shown in parent component when upload is processing**/
              }
            />
          </Box>
        )}

        {enableCopy && (
          <Box left>
            <Button
              textKey={Texts.standardCtaCopyText}
              spacing={smallSpacing}
              onClick={() => onCopyText(getText?.({ jaguarResults }))}
              imageKey={Images.copyWhite}
            />
          </Box>
        )}
      </Box>
      {SuffixComponent && <SuffixComponent />}
      {enableBetaUX && <BetaButton />}
    </Box>
  )
}
