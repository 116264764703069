import styled from '@emotion/styled'
import { FeatureFlag } from '../../../../pure/enums/FeatureFlags'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import {
  VitecNextProductPureInfoItems,
  shouldShowLongWaitMessage
} from '../../../../pure/leopard/VitecNextProductPureInfoItems'
import Colors from '../../../../pure/libs/Colors'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes.js'
import { BorderRadixes } from '../enums/BorderRadixes'
import { Shadows } from '../enums/Shadows'
import { smallSpacing } from '../enums/Spacings'
import { useLeopardOrder } from '../hooks/QueryHooks'
import { useFeatureFlag } from '../hooks/useFeatureFlag'
import { useIsProductEnabled } from '../hooks/useIsProductEnabled'
import { useJaguarResultsExperimental } from '../hooks/useJaguarResults'
import { Texts } from '../libs/Texts'
import { VitecNextProductInfoItem } from '../types/VitecNextProductTypes'
import Box from './Box'
import FigmaText from './FigmaText'
import { VitecNextOrderItemButton } from './VitecNextOrderItemButton'
import { VitecNextOrderItemIconView } from './VitecNextOrderItemIconView'
import { VitecNextOrderItemLongWaitFigmaText } from './VitecNextOrderItemLongWaitFigmaText'
import { VitecNextOrderItemStatus } from './VitecNextOrderItemStatus'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'

type VitecNextOrderItemProps = {
  vitecNextProduct: VitecNextProduct
  vitecNextOrder: VitecNextOrder
  now: string
}

export const VitecNextOrderItem = ({ vitecNextProduct, vitecNextOrder, now }: VitecNextOrderItemProps) => {
  const vitecNextProductInfoItem: VitecNextProductInfoItem = VitecNextProductInfoItems[vitecNextProduct]
  const disableSendOnUploadInfoDone = useFeatureFlag(FeatureFlag.disableSendOnUploadInfoDone)
  const {
    textKey,
    descriptionKey,
    buttons,
    StatusComponent = VitecNextOrderItemStatus,
    DescriptionSuffixComponent = () => null
  } = vitecNextProductInfoItem
  const { data: leopardOrder } = useLeopardOrder(
    getLeopardOrderId({ externalEstateId: vitecNextOrder?.estateId, product: vitecNextProduct })
  )

  const vitecNextOrderId = vitecNextOrder._id

  const { data: results } = useJaguarResultsExperimental(vitecNextOrderId, vitecNextProduct)

  const { data: isProductEnabled, isLoading: isLoadingProduct } = useIsProductEnabled({
    vitecNextOrderId,
    vitecNextProduct
  })

  return (
    <Container fullWidth align="center" fullHeight>
      <VitecNextOrderItemIconView vitecNextProduct={vitecNextProduct} />
      <InnerContainer
        key={vitecNextProductInfoItem.slug}
        spacing={smallSpacing}
        fullPadding
        align="center"
        fullWidth
        fullHeight
        justify="space-between"
      >
        <Box fullWidth align="center" fullPadding spacing={smallSpacing}>
          <Box fullWidth align="center" fullHeight>
            <Box fullWidth>
              <FigmaText textKey={textKey} />
              <Box top spacing={smallSpacing}>
                <FigmaText textKey={descriptionKey} />
                <DescriptionSuffixComponent />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box fullWidth>
          <Box fullWidth>
            <StatusComponent
              vitecNextOrder={vitecNextOrder}
              isProductEnabled={isProductEnabled}
              now={now}
              vitecNextProduct={vitecNextProduct}
            />
          </Box>

          {shouldShowLongWaitMessage({ vitecNextProduct, results }) && (
            <Box top spacing={smallSpacing}>
              <VitecNextOrderItemLongWaitFigmaText vitecNextOrder={vitecNextOrder} />
            </Box>
          )}
          <Box pointer alignSelf="flex-start" direction="row" align="center" fullWidth>
            {buttons.map((button, i) => (
              <Box key={i} top spacing={smallSpacing}>
                <VitecNextOrderItemButton
                  button={button}
                  vitecNOOrder={vitecNextOrder}
                  vitecNextProduct={vitecNextProduct}
                  isProductEnabled={isProductEnabled}
                  index={i}
                  now={now}
                  isLoading={isLoadingProduct}
                />
              </Box>
            ))}
          </Box>
          {VitecNextProductPureInfoItems[vitecNextProduct].enableSendOnDoneEmail && disableSendOnUploadInfoDone && (
            <Box top spacing={smallSpacing}>
              <FigmaText
                textKey={Texts.standardNotificationLongJobEmail}
                text={'No Email will be sent to end customer'}
              />
            </Box>
          )}
        </Box>
      </InnerContainer>
    </Container>
  )
}

const Container = styled(Box)`
  border-radius: ${BorderRadixes.regular};
  box-shadow: ${Shadows.large};
  width: 400px;
  background-color: ${Colors.white};
`

const InnerContainer = styled(Box)`
  background-color: ${Colors.neutral30};
  border-bottom-left-radius: ${BorderRadixes.regular};
  border-bottom-left-radius: ${BorderRadixes.regular};
`
