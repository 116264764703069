import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import React from 'react'
import { smallSpacing } from '../enums/Spacings'
import useAppStateSession from '../hooks/useAppStateSession'
import { Texts } from '../libs/Texts'
import Box from './Box'
import { DashboardLayoutSelectionButton } from './DashboardLayoutSelectionButton'
import FigmaText from './FigmaText'
import { CheckBoxIcon } from './Icons'
import styled from '@emotion/styled'
import Colors from '../../../../pure/libs/Colors'

export function DashboardPreviewDeprecated() {
  const c = useAppStateSession()
  const { enablePreview } = c.state

  const onClickPreview = () => c.setState({ ...c.state, enablePreview: !enablePreview })

  return (
    <Box direction="row" align="center" fullHeight>
      <Box direction="row" align="center">
        <Box onClick={onClickPreview} pointer>
          {enablePreview ? <StyledCheckboxIcon /> : <CheckBoxOutlineBlankIcon color={'white' as any} />}
        </Box>
        <Box left spacing={smallSpacing}>
          <FigmaText textKey={Texts.dashboardSettingPreviewBeforeSave} />
        </Box>
      </Box>
      <Box direction="row" left align="center" fullHeight>
        <Box fullHeight>
          <DashboardLayoutSelectionButton type="list" />
        </Box>
        <Box left spacing={smallSpacing} fullHeight>
          <DashboardLayoutSelectionButton type="grid" />
        </Box>
      </Box>
    </Box>
  )
}

export const StyledCheckboxIcon = styled(CheckBoxIcon)`
  color: ${Colors.white};
`
