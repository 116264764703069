import { AuthErrorCodes } from 'firebase/auth'
import { VitecNextOrderProductProps } from '../types/VitecNextOrderProductProps'
import { addQueryParamsToUrl } from './QueryParamHelper'

enum RoutePath {
  ROOT = '/',
  SANDBOX = '/sandbox',
  DASHBOARD = '/dashboard',
  PRICING = '/pricing',
  PRICING_WEB_IFRAME = '/pricing-web-iframe',
  SIGN_UP = '/signup',
  TERMS_AND_CPNDITIONS = '/terms-and-conditions',
  PRIVACY_POLICY = '/privacy-policy',
  SETTINGS = '/settings',
  EXTERNAL_SESSION = '/external-session',
  VITEC_NEXT_ORDER_LANDING = '/vitec-next-order-landing',
  VITEC_NEXT_ORDER = '/vitec-next-order',
  VITEC_NEXT_ORDER_PRODUCT = '/vitec-next-order/:vitecNextOrderId/:vitecNextProduct',
  VITEC_NEXT_ORDER_PRODUCT_FEEDBACK = '/vitec-next-order/:vitecNextOrderId/:vitecNextProduct/feedback',
  VITEC_NEXT_ORDER_PRODUCT_DIFF = '/vitec-next-order/:vitecNextOrderId/:vitecNextProduct/diff',
  VITEC_NEXT_FAQ = '/vitec-next-faq'
}

export const formatRoutePathVitecNextOrderProduct = (
  p: VitecNextOrderProductProps,
  routePath: RoutePath = RoutePath.VITEC_NEXT_ORDER_PRODUCT
) => formatRoutePath(routePath, p)

export function formatRoutePathVitexNextOrder(vitecNextOrderId: string, routePath = RoutePath.VITEC_NEXT_ORDER) {
  return addQueryParamsToUrl(routePath, { orderId: vitecNextOrderId })
}

const formatRoutePath = (r: RoutePath, o: object) =>
  Object.entries(o).reduce((acc, [k, v]) => acc.replace(`:${k}`, v), r as string)

export default RoutePath
