import styled from '@emotion/styled'
import { SuccessColor } from '../../../../pure/libs/Colors'
import { Error, Success, Warning } from '../../../../pure/libs/ColorsExperimental'
import { DASHBOARD_LIST_ITEM_STATUS_VIEW_SIZE } from '../../../../pure/libs/Consts'
import { IsVitecNextProductEnabledInfo } from '../libs/IsVitecNextProductEnabledInfo'
import Box from './Box'
import { BIG_ICON_SIZE } from './Icons'
import Loader from './Loader'
import Tooltip from './Tooltip'
import { VitecNextOrderItemStatusViewProps } from './VitecNextOrderItemStatusView'

export const DashboardListItemStatusView = (props: VitecNextOrderItemStatusViewProps) => {
  const { type, statusProps } = props
  const Container = type === 'success' ? SuccessContainer : type === 'error' ? ErrorContainer : WarningContainer
  const text =
    props.text || IsVitecNextProductEnabledInfo[statusProps?.isProductEnabled?.code || '']?.getText(statusProps)

  if (type === 'processing')
    return (
      <Box>
        <Loader $loaderColor={SuccessColor} size={BIG_ICON_SIZE} />
      </Box>
    )

  return (
    <Tooltip title={text || ''}>
      <Container fullWidth fullPadding spacing={`${DASHBOARD_LIST_ITEM_STATUS_VIEW_SIZE}px`} align="center"></Container>
    </Tooltip>
  )
}

const BadgeContainer = styled(Box)`
  border-radius: 50%;
`

const SuccessContainer = styled(BadgeContainer)`
  background-color: ${Success};
`

const WarningContainer = styled(BadgeContainer)`
  background-color: ${Warning};
`

const ErrorContainer = styled(BadgeContainer)`
  background-color: ${Error};
`
