import { JaguarResultResponse } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { JaguarEstate } from '../../../../pure/types/JaguarEstate'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { useJaguarEstate } from './QueryHooks'
import { ReducerState } from './useFirestoreQueryHelper'

export function useJaguarResultForVitecNextOrderDeprecated<T extends JaguarResultResponse>({
  vitecNextOrder,
  vitecNextProduct
}: {
  vitecNextOrder?: VitecNextOrder
  vitecNextProduct: VitecNextProduct
}) {
  return useJaguarResultExperimental<T>({
    externalEstateId: vitecNextOrder?.estateId,
    product: vitecNextProduct
  } as LeopardOrder)
}

export function useJaguarResultExperimental<T extends JaguarResultResponse>(l?: LeopardOrder): ReducerState<T> {
  const q = useJaguarEstate(l?.externalEstateId)

  const data = getJaguarResultResponse({ jaguarEstate: q.data, leopardOrder: l })
  const query = { ...q, data } as ReducerState<T>
  return query
}

export function getJaguarResultsForLeopardOrderAndEstate({
  leopardOrders,
  jaguarEstate
}: {
  leopardOrders: LeopardOrder[]
  jaguarEstate?: JaguarEstate
}): (JaguarResultResponse | undefined)[] {
  return leopardOrders.map((leopardOrder) => getJaguarResultResponse({ jaguarEstate, leopardOrder }))
}

function getJaguarResultResponse({
  jaguarEstate,
  leopardOrder
}: {
  jaguarEstate?: JaguarEstate
  leopardOrder?: LeopardOrder
}): JaguarResultResponse | undefined {
  const product = mapProduct(leopardOrder?.product)
  if (!jaguarEstate || !leopardOrder || !product) return undefined
  return jaguarEstate.workflow_history[product]?.find((w) => w.request_id === leopardOrder.requestId)
}

const mapProduct = (product?: VitecNextProduct): keyof JaguarEstate['workflow_history'] | undefined => {
  switch (product) {
    case VitecNextProduct.BASIC_ASSOCIATION_EXPERIMENTAL:
      return 'basic-association'
    case VitecNextProduct.CONSTRUCTION_INFO_EXPERIMENTAL:
      return 'construction-method'
    case VitecNextProduct.AREA_DESCRIPTION_EXPERIMENTAL:
      return 'area-description'
    case VitecNextProduct.IMAGE_CAPTIONS_EXPERIMENTAL:
      return 'image-captions'
    case VitecNextProduct.SELF_DECLERATION_EXPERIMENTAL:
      return 'self-declaration'
    case VitecNextProduct.DESCRIPTIONS_EXPERIMENTAL:
      return 'descriptions'
  }

  return undefined
}
