import { useEffect } from 'react'
import RoutePath, { formatRoutePathVitexNextOrder } from '../../../../pure/libs/RoutePath'
import { VitecNOOrderQueryParams } from '../../../../pure/types/VitecNODerivedTypes'
import Box from '../components/Box'
import { Layout } from '../components/Layout'
import MenuVitecNext from '../components/MenuVitecNext'
import useFirebaseUser from '../components/useFirebaseUser'
import { reallyBigSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { useNavigate } from '../hooks/useNavigate'
import useQueryParams from '../libs/useQueryParams'

export const RootNavigationPage = () => {
  const { data: firebaseUser } = useFirebaseUser()
  const { state } = useAppState()
  const navigate = useNavigate()
  let { orderId: vitecNextOrderId } = useQueryParams<VitecNOOrderQueryParams>()

  let email = firebaseUser?.email || undefined
  if (email === 'anders@gazelle.work') email = 'christopher@gazelle.work'
  // const { data: vitecNextOrders, isLoading } = useLastVitecNextOrder({ email })

  vitecNextOrderId = '' // vitecNextOrderId || vitecNextOrders?.[0]?._id

  useEffect(() => {
    if (state.redirectUrl) return navigate(state.redirectUrl)

    if (vitecNextOrderId) return navigate(formatRoutePathVitexNextOrder(vitecNextOrderId, RoutePath.DASHBOARD))
  }, [vitecNextOrderId])

  // if (isLoading) return <LayoutLoadingPage />

  return (
    <Layout enableFooter={false} MenuComponent={MenuVitecNext} enableRightBottomImage={false}>
      <Box fullWidth alignText="center" top spacing={reallyBigSpacing}>
        Cant find any orders for email: {email}
      </Box>
    </Layout>
  )
}
