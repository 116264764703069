import { VitecNextProduct } from '../leopard/VitecNextProduct.js'
import { VitecNextOrder } from '../types/VitecNODerivedTypes.js'

type Props = {
  externalEstateId?: string
  product: VitecNextProduct
}

export const getLeopardOrderIdForOrder = ({
  vitecNextProduct: product,
  vitecNextOrder
}: {
  vitecNextProduct: VitecNextProduct
  vitecNextOrder?: VitecNextOrder
}): string => getLeopardOrderId({ product, externalEstateId: vitecNextOrder?.estateId })

export function getLeopardOrderId(props: Partial<Props>): string {
  if (!props.externalEstateId) return ''
  if (!props.product) return ''

  const { externalEstateId, product } = props

  return `${externalEstateId}-${product}`
}
