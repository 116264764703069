import useAppState from './useAppState'
import { useAsyncFunction } from './useAsyncFunction'
import { GetJaguarResultsRequest } from '../../../../pure/leopard/GetJaguarResultsRequest'
import { withMemoryCache } from '../../../../pure/libs/withMemoryCache'
import { State } from '../hooks/useAppState'
import { _getJaguarResults } from '../libs/CloudFunctionsApiHandler'
import { JaguarResultResponse } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'

export function useJaguarResultsExperimental<T extends JaguarResultResponse>(
  vitecNextOrderId: string,
  vitecNextProduct: VitecNextProduct
) {
  const { state } = useAppState()

  return useAsyncFunction<T>(() => getJaguarResults({ vitecNextProduct, vitecNextOrderId }, state))
}

export const getJaguarResults = (req: GetJaguarResultsRequest, state: State) =>
  withMemoryCache(
    () => _getJaguarResults(req, state),
    `getJaguarResults_${req.vitecNextOrderId}_${req.vitecNextProduct}`
  )()
