import JsonTexts from '../assets/texts.json'

export const Texts = JsonTexts

export const HardCodedTexts = {
  descriptionsHeader: {
    characters: 'Header',
    name: 'dashboardShortLongTitle',
    texts: {
      en: 'Header',
      no: 'Overskrift'
    },
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '24px',
      fontWeight: 700,
      letterSpacing: '-0.48px',
      color: 'rgba(0, 11, 97, 1)'
    },
    pageName: 'Gazelle'
  },
  descriptionsShort: {
    characters: 'Short',
    name: 'dashboardShortLongTitle',
    texts: {
      en: 'Short',
      no: 'Kort om eiendomen'
    },
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '24px',
      fontWeight: 700,
      letterSpacing: '-0.48px',
      color: 'rgba(0, 11, 97, 1)'
    },
    pageName: 'Gazelle'
  },
  cssSelfDeclerationQuestion: {
    characters: 'Question CSS',
    name: 'cssSelfDeclerationQuestion',
    texts: {},
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '16px',
      fontWeight: 700,
      letterSpacing: 0,
      color: 'rgba(0, 11, 97, 1)'
    },
    pageName: 'Gazelle'
  },
  signInSubheading: {
    name: 'signInSubheading',
    texts: {
      en: 'Sign in to Gazelle.',
      no: 'Logg inn på Gazelle.'
    },
    characters: 'Sign in to Our Big Picture.',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '16px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(9, 30, 66, 1)'
    },
    pageName: 'Signup Sep 2023',
    figmaTextKeyName: 'TXT_SignInSubheading'
  },
  DescriptionsDOrderItemHeader: {
    characters: 'Beskrivelser',
    name: 'DescriptionsDOrderItemHeader',
    texts: {
      en: 'Descriptions',
      no: 'Beskrivelser'
    },
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '24px',
      fontWeight: 700,
      letterSpacing: '-0.48px',
      color: 'rgba(0, 11, 97, 1)'
    },
    pageName: 'Gazelle'
  },
  Inhold: {
    characters: 'Beskrivelser',
    name: 'DescriptionsDOrderItemHeader',
    texts: {
      en: 'Inhold',
      no: 'Inhold'
    },
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '24px',
      fontWeight: 700,
      letterSpacing: '-0.48px',
      color: 'rgba(0, 11, 97, 1)'
    },
    pageName: 'Gazelle'
  },
  allSeparatorOr: {
    name: 'allSeparatorOr',
    characters: 'or',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '11px',
      fontWeight: 500,
      letterSpacing: '0.5px',
      color: 'rgba(200, 197, 208, 1)'
    },
    pageName: 'New base',
    figmaTextKeyName: 'TXT_allSeparatorOr'
  },
  orderViewHeader: {
    name: 'orderViewHeader',
    characters: 'Hjelms gate 2B, Oslo',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '32px',
      fontWeight: 500,
      letterSpacing: '-0.64px',
      color: 'rgba(255, 255, 255, 1)'
    },
    pageName: '🖥️ Gazelle App Production',
    figmaTextKeyName: 'TXT_OrderViewHeader'
  },
  copyToClipBoard: {
    characters: 'Funksjoner',
    name: 'dashboardFeaturesHeader',
    texts: {
      en: 'Copied to clipboard',
      no: 'Kopiert til clipboard'
    },
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '24px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(128, 149, 230, 1)'
    },
    pageName: 'Gazelle'
  },
  vitecNextOrderProductHeaderExperimentalText: {
    characters: 'Funksjoner',
    name: 'dashboardFeaturesHeader',
    texts: {
      en: 'Go to Normal version',
      no: 'Go to Normal version'
    },
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(128, 149, 230, 1)'
    },
    pageName: 'Gazelle'
  },
  vitecNextOrderProductHeaderGoToExperimentalText: {
    characters: 'Funksjoner',
    name: 'dashboardFeaturesHeader',
    texts: {
      en: 'Go to Next version',
      no: 'Go to Next version'
    },
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(128, 149, 230, 1)'
    },
    pageName: 'Gazelle'
  },
  BasicAssociationBaFeedbackAdditionalText: {
    characters: 'Velg tekster for spesifikk tilbakemelding.',
    name: 'BasicAssociationBaFeedbackAdditionalText',
    texts: {
      en: 'Select texts for specific feedback',
      no: 'Velg tekster for spesifikk tilbakemelding.'
    },
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(186, 186, 186, 1)'
    },
    pageName: 'Gazelle'
  },
  cssBaItemHeader: {
    characters: 'BAItemHeader',
    name: 'cssBaItemHeader',
    texts: {},
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '16px',
      fontWeight: 700,
      letterSpacing: 0,
      color: 'rgba(0, 11, 97, 1)'
    },
    pageName: 'Gazelle'
  },
  signInDescription: {
    name: 'signInDescription',
    texts: {
      en: 'You don’t need a password. We’ll email you a magic code.',
      no: 'Du trenger ikke et passord. Vi sender deg en magisk kode på e-post.'
    },
    characters: 'You don’t need a password. We’ll email you a magic code.',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(80, 95, 121, 1)'
    },
    pageName: 'Signup Sep 2023',
    figmaTextKeyName: 'TXT_SignInDescription'
  }
}
