import { addQueryParamsToUrl } from '../../../../pure/libs/QueryParamHelper'
import { formatRoutePathVitexNextOrder } from '../../../../pure/libs/RoutePath'
import { AdValidationQueryParams } from '../../../../pure/types/QueryParamTypes'
import { getQueryParams } from './useQueryParams'

export function pushHighlitedHistoryState({
  vitecNextOrderId,
  highlightedId
}: {
  vitecNextOrderId: string
  highlightedId: string
}) {
  window.history.pushState(
    {},
    '',
    addQueryParamsToUrl<AdValidationQueryParams>(formatRoutePathVitexNextOrder(vitecNextOrderId), { highlightedId })
  )
}

export function popHighlightedHistoryState() {
  const { highlightedId, ...qp } = getQueryParams<AdValidationQueryParams>()
  window.history.pushState({}, '', addQueryParamsToUrl(window.location.origin + window.location.pathname, qp))
}
