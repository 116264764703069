export enum VitecNextProduct {
  IMAGE_CAPTIONS_EXPERIMENTAL = 'IMAGE_CAPTIONS_EXPERIMENTAL',
  CONSTRUCTION_INFO_EXPERIMENTAL = 'CONSTRUCTION_INFO_EXPERIMENTAL',
  BASIC_ASSOCIATION_EXPERIMENTAL = 'BASIC_ASSOCIATION_EXPERIMENTAL',
  SELF_DECLERATION_EXPERIMENTAL = 'SELF_DECLERATION_EXPERIMENTAL',
  DESCRIPTIONS_EXPERIMENTAL = 'DESCRIPTIONS_EXPERIMENTAL',
  AREA_DESCRIPTION_EXPERIMENTAL = 'AREA_DESCRIPTION_EXPERIMENTAL'
}

export const LiveProducts: { [key in VitecNextProduct]: boolean } = {
  IMAGE_CAPTIONS_EXPERIMENTAL: true,
  CONSTRUCTION_INFO_EXPERIMENTAL: true,
  DESCRIPTIONS_EXPERIMENTAL: true,
  BASIC_ASSOCIATION_EXPERIMENTAL: true,
  AREA_DESCRIPTION_EXPERIMENTAL: true,
  SELF_DECLERATION_EXPERIMENTAL: true
}

export const LiveProductNames = Object.entries(LiveProducts)
  .filter(([_, value]) => value)
  .map(([key]) => key as VitecNextProduct)
