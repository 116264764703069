import { SelfDeclarationResultsResponse } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { useVitecNOOrder } from '../hooks/QueryHooks'
import { useJaguarResultForVitecNextOrderDeprecated } from '../hooks/useJaguarResults'
import { formatSelfDeclarationItem } from '../libs/SelfDeclarationHelper'
import { HardCodedTexts, Texts } from '../libs/Texts'
import Box from './Box'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'

export const DashboardListItemDetailsSelfDeclarationComponent = (props: VitecNextOrderProductProps) => {
  const { vitecNextOrderId, vitecNextProduct } = props
  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)

  const { data: jaguarResults } = useJaguarResultForVitecNextOrderDeprecated<SelfDeclarationResultsResponse>({
    vitecNextOrder,
    vitecNextProduct
  })

  const items = jaguarResults?.structured_outputs?.items || []

  return (
    <Box fullWidth>
      {items.map((item, i) => {
        const { answer, question } = formatSelfDeclarationItem(item)

        if (!answer) return null

        return (
          <Box top fullWidth key={i} spacing={tinySpacing}>
            <Box left spacing={smallSpacing} fullWidth>
              <FigmaText styleTextKey={HardCodedTexts.cssBaItemHeader} text={question} />
              <Box>
                <FigmaTextWithLinebreaks textKey={Texts.BasicAssociationBaAnimal} text={answer} />
              </Box>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
