import styled from '@emotion/styled'
import { FeatureFlag } from '../../../../pure/enums/FeatureFlags'
import { AreaDescriptionResultsResponse } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import Colors from '../../../../pure/libs/Colors'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import { useLeopardOrder, useVitecNOOrder } from '../hooks/QueryHooks'
import { useFeatureFlag } from '../hooks/useFeatureFlag'
import { useJaguarResultsExperimental } from '../hooks/useJaguarResults'
import { VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH } from '../libs/HardCodedSizes'
import Images from '../libs/Images'
import { Texts } from '../libs/Texts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { JsonView } from './JsonView'
import Loader from './Loader'
import { LoadingPage } from './LoadingPage'
import { VitecNextOrderProductBackButton } from './VitecNextOrderProductBackButton'
import { VitecNextOrderProductButtonsDeprecated as VitecNextOrderProductButtons } from './VitecNextOrderProductButtonsDeprecated'
import { VitecNextOrderProductFeedbackButton } from './VitecNextOrderProductFeedbackButton'
import { VitecNextOrderProductHeader } from './VitecNextOrderProductHeader'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'

export const VitecNextOrderProductAreaDescriptionExperimental = (props: VitecNextOrderProductProps) => {
  const enableDebug = useFeatureFlag(FeatureFlag.enableDebug)
  const { vitecNextOrderId, vitecNextProduct } = props
  const { data: vitecNextOrder, isLoading: isLoadingVitecNextOrder } = useVitecNOOrder(vitecNextOrderId)
  const item = VitecNextProductInfoItems[vitecNextProduct]

  const { data: leopardOrder } = useLeopardOrder(
    getLeopardOrderId({ externalEstateId: vitecNextOrder?.estateId, product: vitecNextProduct })
  )

  const { requestId } = leopardOrder || {}

  const { data: jaguarResults, isLoading: isLoadingJaguarResults } =
    useJaguarResultsExperimental<AreaDescriptionResultsResponse>(vitecNextOrderId, vitecNextProduct)

  const status = jaguarResults?.status

  const isLoading = isLoadingVitecNextOrder || isLoadingJaguarResults

  if (!vitecNextOrder) return <Box />
  if (!leopardOrder) return <Box />

  const text = item.getText?.({ jaguarResults })

  return (
    <Box fullWidth fullPadding direction="row" justify="space-between" spacing={bigSpacing}>
      <Box>
        <VitecNextOrderProductBackButton vitecNextOrderId={vitecNextOrderId} />
      </Box>
      <Box fullWidth width={`${VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH}px`}>
        <VitecNextOrderProductHeader
          vitecNextOrder={vitecNextOrder}
          headerTextKey={item.textKey}
          vitecNextProduct={vitecNextProduct}
        />

        <Box top>
          <FigmaImageContainer imageKey={Images.gazelleInCircle} />
        </Box>

        <Box fullWidth top spacing={smallSpacing}>
          <VitecNextOrderProductButtons vitecNextOrderId={vitecNextOrderId} vitecNextProduct={vitecNextProduct} />
        </Box>

        <Box fullWidth fullPadding></Box>

        {status === 'processing' && <LoadingPage />}
        {status === 'success' && (
          <Box fullWidth>
            <Box fullWidth>
              <Container fullWidth fullPadding position="relative">
                <Box direction="row" align="center">
                  <FigmaText textKey={Texts.ConstructionCiTextHeader} />
                </Box>
                <Box top>
                  <VitecNextOrderProductFeedbackButton {...props} />
                </Box>
                {isLoading && (
                  <Box fullWidth align="center" fullPadding>
                    <Loader />
                  </Box>
                )}
                <Box fullWidth top>
                  <FigmaTextWithStyle
                    Component={FigmaTextWithLinebreaks as any}
                    separator={<span />}
                    separatorWhenEmptyContent={<br />}
                    type="body1"
                    text={text || ''}
                  />
                </Box>
                {enableDebug && jaguarResults && (
                  <Box top>
                    <JsonView data={jaguarResults} />
                  </Box>
                )}
              </Container>
            </Box>
          </Box>
        )}
      </Box>
      <Box />
    </Box>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`
