import { useEffect, useState } from 'react'
import { LiveProductNames } from '../../../../pure/leopard/VitecNextProduct'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { VitecNextProductInfoItems, validateVitecNextProductInfoItem } from '../components/VitecNextProductInfoItems'
import { useDrawerContext } from '../components/useDrawerContext'
import { captureException } from '../libs/ErrorHelper'
import {
  VitecNextItemsValidationArgs,
  VitecNextProductInfoItem,
  VitecNextProductInfoItemValidationItem
} from '../types/VitecNextProductTypes'
import { useAreProductsEnabledCache } from './useIsProductEnabled'
import { useNavigate } from './useNavigate'
import { useVitecNextEstateForOrder } from './useVitecNextEstateForOrder'
import { useLatestLeopardOrders } from '../components/useLatestLeopardOrders'

export const useVitecNextValidationItems = ({
  vitecNextOrder
}: {
  vitecNextOrder?: VitecNextOrder
}): { data: VitecNextProductInfoItemValidationItem[]; isLoading: boolean } => {
  const drawerContext = useDrawerContext('ad_validation')
  const navigate = useNavigate({ target: '_blank', enablePreserveQueryParams: false })
  const [validationItems, setValidationItems] = useState<VitecNextProductInfoItemValidationItem[]>([])
  const { data: leopardOrders = [] } = useLatestLeopardOrders(vitecNextOrder)
  const { data: estate, isLoading: isLoadingEstate } = useVitecNextEstateForOrder({
    orderId: vitecNextOrder?.orderId || '',
    useCache: false
  })

  const { data: areProductsEnabled, isLoading: isLoadingProducts } = useAreProductsEnabledCache({
    vitecNextOrderId: vitecNextOrder?.orderId
  })

  const isLoading = isLoadingEstate || isLoadingProducts || false

  useEffect(() => {
    if (!vitecNextOrder) return
    if (!estate?.estateId) return

    const rows = LiveProductNames.map((vitecNextProduct) => ({
      leopardOrder: leopardOrders.find((order) => order?.product === vitecNextProduct),
      vitecNextProduct
    }))

    const acc: VitecNextProductInfoItemValidationItem[] = []

    Promise.all(
      rows.map(async (row) => {
        const { leopardOrder, vitecNextProduct } = row

        const infoItem: VitecNextProductInfoItem = VitecNextProductInfoItems[vitecNextProduct]

        if (!infoItem) return acc
        const { validate } = infoItem
        const props: VitecNextItemsValidationArgs = {
          vitecNextOrder,
          vitecNextProduct,
          leopardOrder,
          estate,
          isVitecNextProductEnabled: areProductsEnabled?.[vitecNextProduct],
          navigate,
          drawerContext
        }

        const defaultItems: VitecNextProductInfoItemValidationItem[] = validateVitecNextProductInfoItem?.(props) || []

        if (defaultItems.length > 0) return defaultItems

        return validate?.(props) || []
      })
    )
      .then((sets) => setValidationItems(sets.flat()))
      .catch(captureException)
  }, [vitecNextOrder?.orderId, estate?.estateId, areProductsEnabled, leopardOrders])

  return { data: validationItems, isLoading }
}
