import { PropsWithChildren, useEffect } from 'react'
import { useVitecNOOrder } from '../hooks/QueryHooks'
import { usePdfViewerPropSetsForBasicAssociationResults } from '../hooks/usePdfViewerPropSetsForBasicAssociationResults'
import { useVitecNextValidationItems } from '../hooks/useVitecNextValidationItems'
import Box from './Box'
import { DashboardStatusItemViewPopperRow } from './DashboardStatusItemViewPopperRow'
import { LayoutLoadingPage } from './LoadingPage'
import { WithPdfDrawer } from './WithPdfDrawer'
import { useDrawerContext } from './useDrawerContext'

import { BasicAssociationResultsResponse } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { getBasicAssociationFieldsFromJaguarResult } from '../../../../pure/libs/getBasicAssociationFieldsFromJaguarResult'
import { AdValidationQueryParams } from '../../../../pure/types/QueryParamTypes'
import { useBasicAssociaionFieldsState } from '../hooks/useBasicAssociaionFieldsState'
import { useJaguarResultForVitecNextOrderDeprecated } from '../hooks/useJaguarResults'
import { inStatusTypeOrder } from '../libs/inStatusTypeOrder'
import useQueryParams from '../libs/useQueryParams'
import { useClickUpDown } from './useClickUpDown'

export const WithAdValidationPdfDrawer = ({
  vitecNextOrderId,
  children,
  drawerContext
}: PropsWithChildren<{ vitecNextOrderId: string; drawerContext: ReturnType<typeof useDrawerContext> }>) => {
  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)
  const { drawerState } = drawerContext
  const qp = useQueryParams<AdValidationQueryParams>()

  const { data: jaguarResult, isLoading: isLoadingJaguarResults } =
    useJaguarResultForVitecNextOrderDeprecated<BasicAssociationResultsResponse>({
      vitecNextOrder,
      vitecNextProduct: VitecNextProduct.BASIC_ASSOCIATION_EXPERIMENTAL
    })

  const { basicAssociationFields, updateBasicAssociationFields } = useBasicAssociaionFieldsState(vitecNextOrderId)

  const highlitedField = basicAssociationFields?.[qp.highlightedId]

  useEffect(() => {
    if (isLoadingJaguarResults === true) return
    const items = jaguarResult?.structured_outputs?.basic_association_info
    if (!items) return
    const fields = getBasicAssociationFieldsFromJaguarResult(items)
    fields && updateBasicAssociationFields(fields)
  }, [isLoadingJaguarResults])

  const pdfViewerPropSets = usePdfViewerPropSetsForBasicAssociationResults({ vitecNextOrderId })

  let { data: validationItems = [] } = useVitecNextValidationItems({ vitecNextOrder })

  validationItems = validationItems.sort(inStatusTypeOrder)

  const LeftComponent =
    validationItems.length > 0
      ? () => (
          <Box>
            {validationItems.sort(inStatusTypeOrder).map((item, key) => (
              <DashboardStatusItemViewPopperRow
                key={key}
                item={item}
                isHighlighted={item.id === drawerState.highlightedField?.id}
                enableCTA={false}
              />
            ))}
          </Box>
        )
      : undefined

  useEffect(() => {
    // Highlitning a field is broken if validationItems is rerendered, hence highlitning need to be done after
    if (validationItems.length === 0) return
    if (!qp.highlightedId) return
    if (!highlitedField) return
    drawerContext.highlight(highlitedField)
  }, [qp.highlightedId, highlitedField, validationItems.length])

  const items = validationItems.filter((i) => !!i.onClick)
  const selectedId = highlitedField?.id

  useClickUpDown({
    items,
    selectedId,
    onClickDown: (items, currentIndex) => items[currentIndex + 1].onClick?.(),
    onClickUp: (items, currentIndex) => items[currentIndex - 1].onClick?.()
  })

  if (!vitecNextOrder) return <LayoutLoadingPage />

  return (
    <WithPdfDrawer pdfViewerPropSets={pdfViewerPropSets} drawerContext={drawerContext} LeftComponent={LeftComponent}>
      {children}
    </WithPdfDrawer>
  )
}
