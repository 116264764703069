import { Dispatch, SetStateAction, useEffect } from 'react'
import React from 'react'

export function useState<T>(
  value?: T,
  opts?: { enabled?: boolean }
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] {
  const { enabled = true } = opts || {}
  const context = React.useState(enabled ? value : undefined)
  const [_, setState] = context

  useEffect(() => {
    if (!value) return
    if (enabled) setState(value)
  }, [enabled])

  return context
}
