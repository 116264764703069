export enum CloudFunction {
  deleteAccount = 'deleteAccount',
  createAuthenticationTokenForLoginToken = 'createAuthenticationTokenForLoginToken',
  createLoginWithCodeToken = 'createLoginWithCodeToken',
  processExternalSession = 'processExternalSession',
  createSessionForUnpayedSession = 'createSessionForUnpayedSession',
  getImagesForVitecEstate = 'getImagesForVitecEstate',
  createAuthenticationTokenForVitecCustomer = 'createAuthenticationTokenForVitecCustomer',
  getStripeSubscriptions = 'getStripeSubscriptions',
  createAuthenticationTokenForUid = 'createAuthenticationTokenForUid',
  deliverViteNOCaptions = 'deliverViteNOCaptions',
  createJaguarOrderForVitecNextOrder = 'createJaguarOrderForVitecNextOrder',
  isVitecNextProductEnabled = 'isVitecNextProductEnabled',
  getEstateForVitecNextOrder = 'getEstateForVitecNextOrder',
  fulfillLeopardOrder = 'fulfillLeopardOrder',
  getJaguarResults = 'getJaguarResults',
  onVitecNextOrderCreated = 'onVitecNextOrderCreated',
  areGazelleProductsEnabled = 'areGazelleProductsEnabled'
}

export enum StripeCloudFunction {
  createStripePortalLink = 'ext-firestore-stripe-payments-createPortalLink'
}

export default CloudFunction
