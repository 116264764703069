import React from 'react'
import { getLeopardOrderIdForOrder } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { smallSpacing } from '../enums/Spacings'
import { useLeopardOrder, useVitecNOOrder } from '../hooks/QueryHooks'
import { upsertLeopardOrder } from '../libs/DBApiHandler'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import Images from '../libs/Images'
import { Texts } from '../libs/Texts'
import Box from './Box'
import Button from './Button'
import { VitecNextOrderItemStatusView } from './VitecNextOrderItemStatusView'

export const VitecNextOrderProductButtonsFeedback = (
  props: {
    feedbackText: string
  } & VitecNextOrderProductProps
) => {
  const { vitecNextOrderId, vitecNextProduct, feedbackText } = props
  const [isLoading, setIsLoading] = React.useState(false)
  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)
  const { data: leopardOrder } = useLeopardOrder(getLeopardOrderIdForOrder({ vitecNextProduct, vitecNextOrder }))
  const [isSuccess, setIsSuccess] = React.useState(false)

  const onClick = () =>
    leopardOrder &&
    Promise.resolve()
      .then(() => upsertLeopardOrder({ ...leopardOrder, feedbackText }))
      .then(() => setIsSuccess(true))
      .catch(captureAndNotifyError)

  if (isSuccess)
    return (
      <Box>
        <VitecNextOrderItemStatusView type="success" textKey={Texts.standardFeedbackSent} statusProps={undefined} />
      </Box>
    )

  return (
    <Button
      textKey={Texts.standardFeedbackSend}
      spacing={smallSpacing}
      onClick={onClick}
      loading={isLoading}
      imageKey={Images.uploadArrowWhite}
    />
  )
}
