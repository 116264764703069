/* eslint-disable no-console */
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { getInitialItem, formatLocalStorageItem } from './useAppStateHelper'
import { DEFAULT_OPTIONS, Options, StorageKey, StorageType, getElligebleStorage } from './useLocalStorageItemHelper'

export const useStorageItem = <T>(
  key: StorageKey,
  defaultValue: T,
  { ignoredKeys = [], storageType = StorageType.LOCAL_STORAGE }: Options = DEFAULT_OPTIONS
): { storageItem: T; setStorageItem: Dispatch<SetStateAction<T>> } => {
  const Storage = getElligebleStorage(storageType)
  const stored = Storage.getItem(key)
  const initial = getInitialItem<T>(stored, defaultValue)

  const [storageItem, setStorageItem] = useState<T>(initial)

  useEffect(() => {
    try {
      let _storageItem = storageItem
      // eslint-disable-next-line prettier/prettier
      if(ignoredKeys.length > 0) _storageItem = formatLocalStorageItem(_storageItem, ignoredKeys)
      Storage.setItem(key, JSON.stringify(_storageItem))
    } catch (error) {
      console.warn(error)
    }
  }, [key, storageItem])

  return { storageItem, setStorageItem }
}
