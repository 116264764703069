import { groupBy } from '../../../../pure/libs/Common'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { useLeopardOrdersForEstate } from '../hooks/QueryHooks'
import { dayjs } from '../libs/DayJsHelper'

export const useLatestLeopardOrders = (vitecNextOrder?: VitecNextOrder) => {
  const q = useLeopardOrdersForEstate(vitecNextOrder?.estateId)

  return q

  q.data = Object.entries(groupBy(q.data || [], 'product')).map(
    ([product, orders]) => orders.sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf())[0]
  )

  return q
}
