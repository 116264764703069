import { VitecNextOrderItemStatusViewType } from '../components/VitecNextOrderItemStatusView'
import { VitecNextProductInfoItemValidationItem } from '../types/VitecNextProductTypes'

const Order: {
  [property in VitecNextOrderItemStatusViewType]: number
} = {
  processing: 0,
  error: 1,
  warning: 2,
  success: 3
}

export const inStatusTypeOrder = (
  a: VitecNextProductInfoItemValidationItem,
  b: VitecNextProductInfoItemValidationItem
): number => Order[a.type] - Order[b.type]
