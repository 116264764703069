import { LiveProductNames, LiveProducts } from '../../../../pure/leopard/VitecNextProduct'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { useAsyncFunction } from '../hooks/useAsyncFunction'
import { getJaguarResults } from '../hooks/useJaguarResults'
import { isFinished } from '../libs/JaguarHelper'
import { Texts } from '../libs/Texts'
import Box from './Box'
import FigmaText from './FigmaText'
import Loader from './Loader'
import { ProgressBarView } from './ProgressBar'
import { useLatestLeopardOrders } from './useLatestLeopardOrders'

export const WIDTH = 200
export const all = LiveProductNames.length

export function DashboardStatusTextCreated({ vitecNextOrder }: { vitecNextOrder?: VitecNextOrder }) {
  const { state } = useAppState()
  const { data: leopardOrders = [], isLoading } = useLatestLeopardOrders(vitecNextOrder)
  const { data: completedOrders } = useAsyncFunction(
    () =>
      Promise.all(
        leopardOrders
          .filter((v) => LiveProducts[v.product])
          .map(async (v) => {
            const results = await getJaguarResults(
              { vitecNextProduct: v.product, vitecNextOrderId: vitecNextOrder?._id as string },
              state
            )

            return {
              leopardOrder: v,
              results
            }
          })
      ).then((rows) => rows.filter(isFinished).map((r) => r.leopardOrder)),
    { enabled: !isLoading }
  )

  if (isLoading) return <Loader />

  if (!completedOrders) return <Loader />

  const percentage = (completedOrders.length / all) * 100

  return (
    <Box style={{ marginTop: -10 /* Lineheight issue with below textkey */ }}>
      <Box direction="row" justify="space-between" align="center" fullWidth>
        <FigmaText textKey={Texts.dashboardDashboardListTextsGeneratedLabel} />
        <FigmaText textKey={Texts.cssDashboardListStatusScore} text={`${percentage.toFixed(0)}%`} />
      </Box>
      <Box width={`${WIDTH}px`} top spacing={tinySpacing}>
        <DashboardStatusProgressBarView progress={percentage} />
      </Box>
    </Box>
  )
}

const DashboardStatusProgressBarView = (props) => (
  <ProgressBarView
    sx={{
      'background-color': 'white'
    }}
    {...props}
  />
)
