import { GetEstateForVitecNextOrderRequest } from '../../../../pure/leopard/GetEstatenForVitecNextOrderRequest'
import { withMemoryCache } from '../../../../pure/libs/withMemoryCache'
import { getEstateForVitecNextOrder } from '../libs/CloudFunctionsApiHandler'
import useAppState from './useAppState'
import { useAsyncFunction } from './useAsyncFunction'

export function useVitecNextEstateForOrder(req: GetEstateForVitecNextOrderRequest) {
  const { state } = useAppState()
  return useAsyncFunction(
    () => withMemoryCache(() => getEstateForVitecNextOrder(req, state), `getEstateForVitecNextOrder_${req.orderId}`)(),
    { enabled: !!req?.orderId }
  )
}
