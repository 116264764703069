import { isProcessingUpload } from '../libs/VitecNextOrderItemHelper'
import { Upload } from '../types/JaguarTypes'
import { JaguarResultResponse } from './JaguarExperimentalTypesDerived'
import { VitecNextProduct } from './VitecNextProduct'

type VitexNextProductPureInfoItem = {
  enableSendOnDoneEmail?: boolean
  vitecNextProductExperimental?: VitecNextProduct
}

export const VitecNextProductPureInfoItems: {
  [key in VitecNextProduct]: VitexNextProductPureInfoItem
} = {
  [VitecNextProduct.AREA_DESCRIPTION_EXPERIMENTAL]: {},
  [VitecNextProduct.DESCRIPTIONS_EXPERIMENTAL]: {
    enableSendOnDoneEmail: true
  },
  [VitecNextProduct.IMAGE_CAPTIONS_EXPERIMENTAL]: {
    enableSendOnDoneEmail: true
  },
  [VitecNextProduct.CONSTRUCTION_INFO_EXPERIMENTAL]: {},
  [VitecNextProduct.BASIC_ASSOCIATION_EXPERIMENTAL]: {
    enableSendOnDoneEmail: true
  },
  [VitecNextProduct.SELF_DECLERATION_EXPERIMENTAL]: {}
}

export function shouldShowLongWaitMessage({
  results,
  vitecNextProduct
}: {
  results?: JaguarResultResponse
  vitecNextProduct: VitecNextProduct
}) {
  return shouldSendOnUploadDoneInfoEmail({ vitecNextProduct }) && isProcessingUpload(results)
}

export function shouldSendOnUploadDoneInfoEmail({ vitecNextProduct }: { vitecNextProduct: VitecNextProduct }) {
  return VitecNextProductPureInfoItems[vitecNextProduct]?.enableSendOnDoneEmail
}
