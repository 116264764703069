import { DescriptionTypes } from '../../../../pure/libs/VitecNextOrderProductDescriptionsItemsPure'
import { TextKey } from '../../../../pure/types/Antiloop'
import { HardCodedTexts, Texts } from './Texts'

export const VitecNextOrderProductDescriptionsItems: {
  [property in DescriptionTypes]: {
    textKey?: TextKey
  }
} = {
  short_description: {
    textKey: HardCodedTexts.descriptionsHeader
  },
  long_description: {
    textKey: HardCodedTexts.descriptionsShort
  },
  innhold: {
    textKey: HardCodedTexts.Inhold
  }
}

export const DescriptionKeysAll = Object.keys(VitecNextOrderProductDescriptionsItems)
