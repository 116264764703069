import styled from '@emotion/styled'
import React, { useRef } from 'react'
import { Error, Success, Warning } from '../../../../pure/libs/ColorsExperimental'
import { TextKey } from '../../../../pure/types/Antiloop'
import { BorderRadixes } from '../enums/BorderRadixes'
import { minSpacing, tinySpacing } from '../enums/Spacings'
import { Texts } from '../libs/Texts'
import { VitecNextProductInfoItemValidationItem } from '../types/VitecNextProductTypes'
import Box from './Box'
import { DashboardStatusItemViewPopper } from './DashboardStatusItemViewPopper'
import FigmaText from './FigmaText'
import { VitecNextOrderItemStatusViewType } from './VitecNextOrderItemStatusView'

export const DashboardStatusItemView = (props: {
  type: VitecNextOrderItemStatusViewType
  validationItems: VitecNextProductInfoItemValidationItem[]
}) => {
  const { validationItems } = props
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const { type } = props
  const ref = useRef<HTMLDivElement>(null)
  const Container = type === 'success' ? SuccessContainer : type === 'error' ? ErrorContainer : WarningContainer
  const { styleTextKey, textKey } = DashboardStatusItemViewInfo[type]
  const n = validationItems.length

  const mouseProps = {
    onMouseEnter: () => setAnchorEl(ref.current),
    onMouseLeave: () => setAnchorEl(null)
  }

  if (n === 0) return null

  return (
    <Box>
      <DashboardStatusItemViewPopper anchorEl={anchorEl} validationItems={validationItems} {...mouseProps} />
      <Container
        direction="row"
        align="center"
        fullPadding
        spacing={minSpacing}
        justify="center"
        ref={ref}
        pointer
        {...mouseProps}
      >
        <Box>
          <FigmaText styleTextKey={styleTextKey} text={n.toString()} />
        </Box>
        <Box left spacing={tinySpacing}>
          <FigmaText styleTextKey={styleTextKey} textKey={textKey} />
        </Box>
      </Container>
    </Box>
  )
}

const DashboardStatusItemViewInfo: {
  [property in VitecNextOrderItemStatusViewType]: {
    styleTextKey: TextKey
    textKey: TextKey
  }
} = {
  success: {
    styleTextKey: Texts.cssDashboardValidationValueBlack,
    textKey: Texts.dashboardDashboardListValidationOk
  },
  warning: {
    styleTextKey: Texts.cssDashboardValidationValueBlack,
    textKey: Texts.dashboardDashboardListValidationWarning
  },
  error: {
    styleTextKey: Texts.cssDashboardValidationValueWhite,
    textKey: Texts.dashboardDashboardListValiationError
  },
  processing: undefined as any
}

const WIDTH = 100
const BadgeContainer = styled(Box)`
  border: 1px solid white;
  border-radius: ${BorderRadixes.regular};
  width: ${WIDTH}px;
`

const SuccessContainer = styled(BadgeContainer)`
  background-color: ${Success};
`

const WarningContainer = styled(BadgeContainer)`
  background-color: ${Warning};
`

const ErrorContainer = styled(BadgeContainer)`
  background-color: ${Error};
`
