import Menu from '@mui/material/Menu'
import { useNavigate } from 'react-router-dom'
import { withStopProgagation } from '../../../../pure/libs/HtmlHelper'
import RoutePath from '../../../../pure/libs/RoutePath'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import useAppState from '../hooks/useAppState'
import { MenuItem } from './MenuItem'
import { Props } from './UserWidgetMenuLoggedIn'
import { setRedirectUrl } from './setRedirectUrl'

export function UserWidgetMenuLoggedOut(props: Props) {
  const { anchorEl } = props
  const navigate = useNavigate()
  const context = useAppState()

  const onClick = () => {
    props.onClose()
    setRedirectUrl(context)
    navigate(RoutePath.ROOT)
  }

  const onClose = withStopProgagation(props.onClose)

  return (
    <div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
        <MenuItem onClick={onClick} textKey={TextsDeprecated.login} />
      </Menu>
    </div>
  )
}
