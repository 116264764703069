import styled from '@emotion/styled'
import Colors from '../../../../pure/libs/Colors'
import { BorderRadixes } from '../enums/BorderRadixes'
import Box from './Box'
import { Props, VitecNextOrderProductButtons } from './VitecNextOrderProductButtons'

export function VitecNextOrderProductButtonsDeprecated(props: Props) {
  return (
    <Container fullWidth fullPadding position="relative">
      <VitecNextOrderProductButtons {...props} />
    </Container>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`
