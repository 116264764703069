import { useParams } from 'react-router-dom'
import { Layout } from '../components/Layout'
import { VitecNextOrderProduct } from '../components/VitecNextOrderProduct'
import { VitecNextOrderRedirectPage } from './DashboardPage'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'

export const VitecNextOrderProductPage = () => {
  const { vitecNextOrderId, vitecNextProduct } = useParams<VitecNextOrderProductProps>()

  if (!vitecNextOrderId) return <Layout enableMenu />
  if (!vitecNextProduct) return <VitecNextOrderRedirectPage vitecNextOrderId={vitecNextOrderId} />

  return <VitecNextOrderProduct vitecNextOrderId={vitecNextOrderId} vitecNextProduct={vitecNextProduct} />
}
