import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import RoutePath from '../../../../pure/libs/RoutePath.js'
import { VitecNOOrderQueryParams } from '../../../../pure/types/VitecNODerivedTypes'
import { Dashboard } from '../components/Dashboard'
import { addQueryParamsToUrl } from '../hooks/useQueryparamHelper.js'
import useQueryParams from '../libs/useQueryParams'

export const DashboardPage = () => {
  const { orderId } = useQueryParams<VitecNOOrderQueryParams>()

  return <Dashboard vitecNextOrderId={orderId} />
}

export const VitecNextOrderRedirectPage = ({ vitecNextOrderId }: { vitecNextOrderId: string }) => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(addQueryParamsToUrl(RoutePath.DASHBOARD, { orderId: vitecNextOrderId }))
  }, [])

  return <Dashboard vitecNextOrderId={vitecNextOrderId} />
}
