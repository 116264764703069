import styled from '@emotion/styled'
import { Error, Success, Warning } from '../../../../pure/libs/ColorsExperimental'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { Texts } from '../libs/Texts'
import { VitecNextProductInfoItemValidationItem } from '../types/VitecNextProductTypes'
import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { DashboardStatusItemViewPopperRowForwardArrow } from './Icons'
import { NoWrapFigmaText } from './StyledComponents'
import Colors from '../../../../pure/libs/Colors'

export function DashboardStatusItemViewPopperRow({
  item,
  isHighlighted,
  enableCTA = true
}: {
  item: VitecNextProductInfoItemValidationItem
  isHighlighted?: boolean
  enableCTA?: boolean
}) {
  const { type, ctaText } = item

  const BadgeContainer = type === 'success' ? SuccessContainer : type === 'error' ? ErrorContainer : WarningContainer

  return (
    <Container
      fullWidth
      fullPadding
      spacing={smallSpacing}
      onClick={item.onClick}
      pointer={!!item.onClick}
      isHighlighted={isHighlighted}
    >
      <Box direction="row" align="center" justify="space-between" fullWidth>
        <Box direction="row" align="center" fullWidth>
          <Box>
            <BadgeContainer fullPadding spacing={tinySpacing} />
          </Box>
          <Box left spacing={smallSpacing} fullWidth>
            <NoWrapFigmaText styleTextKey={Texts.cssValidationMessageHeader} text={item.header} />
          </Box>
        </Box>
        <Box visibility={item.onClick && enableCTA ? 'visible' : 'hidden'} fullWidth align="flex-end" left>
          <Button buttonType="secondary" spacing={smallSpacing} verticalSpacing={'0px'}>
            <Box direction="row" align="center">
              <Box>
                <FigmaText textKey={Texts.componentsValidationMessagesCtaReviewDocument} text={ctaText} />
              </Box>
              <Box>
                <DashboardStatusItemViewPopperRowForwardArrow />
              </Box>
            </Box>
          </Button>
        </Box>
      </Box>
      <Box fullWidth>
        <FigmaTextWithLinebreaks textKey={Texts.cssValidationMessageText} text={item.message} />
      </Box>
    </Container>
  )
}

const Container = styled(Box)<{ isHighlighted?: boolean }>`
  ${({ isHighlighted }) => isHighlighted && `background-color: ${Colors.neutral30};`}
`

const StatusContainer = styled(Box)`
  border-radius: 50%;
`
const SuccessContainer = styled(StatusContainer)`
  background-color: ${Success};
`
const WarningContainer = styled(StatusContainer)`
  background-color: ${Warning};
`
const ErrorContainer = styled(StatusContainer)`
  background-color: ${Error};
`
