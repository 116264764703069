import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { smallSpacing } from '../enums/Spacings'
import { useVitecNextValidationItems } from '../hooks/useVitecNextValidationItems'
import { Texts } from '../libs/Texts'
import Box from './Box'
import { DashboardStatusItemView } from './DashboardStatusItemView'
import FigmaText from './FigmaText'

export function DashboardStatusValidation({ vitecNextOrder }: { vitecNextOrder?: VitecNextOrder }) {
  const { data: validationItems = [] } = useVitecNextValidationItems({ vitecNextOrder })

  if (validationItems.length === 0) return <Box />

  return (
    <Box direction="row" align="center" fullWidth>
      <Box>
        <FigmaText textKey={Texts.dashboardDashboardListTextsValidated} />
      </Box>
      <Box left>
        <DashboardStatusItemView type="success" validationItems={validationItems} />
      </Box>
      <Box left spacing={smallSpacing}>
        <DashboardStatusItemView type="warning" validationItems={validationItems} />
      </Box>
      <Box left spacing={smallSpacing}>
        <DashboardStatusItemView type="error" validationItems={validationItems} />
      </Box>
    </Box>
  )
}
