import { SelfDeclarationResultsResponse } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { useLeopardOrder, useVitecNOOrder } from '../hooks/QueryHooks'
import { useJaguarResultsExperimental } from '../hooks/useJaguarResults'
import { formatSelfDeclarationItem } from '../libs/SelfDeclarationHelper'
import { HardCodedTexts, Texts } from '../libs/Texts'
import Box from './Box'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'

export const DashboardListItemDetailsSelfDeclarationComponent = (props: VitecNextOrderProductProps) => {
  const { vitecNextOrderId, vitecNextProduct } = props

  const { data: jaguarResults } = useJaguarResultsExperimental<SelfDeclarationResultsResponse>(
    vitecNextOrderId,
    vitecNextProduct
  )

  const items = jaguarResults?.structured_outputs?.items || []

  return (
    <Box fullWidth>
      {items.map((item, i) => {
        const { answer, question } = formatSelfDeclarationItem(item)

        if (!answer) return null

        return (
          <Box top fullWidth key={i} spacing={tinySpacing}>
            <Box left spacing={smallSpacing} fullWidth>
              <FigmaText styleTextKey={HardCodedTexts.cssBaItemHeader} text={question} />
              <Box>
                <FigmaTextWithLinebreaks textKey={Texts.BasicAssociationBaAnimal} text={answer} />
              </Box>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
