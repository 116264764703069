import React, { useEffect, useRef } from 'react'
import { JaguarBoundingBox as BoundingBox } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { isSameBoundingBox } from '../libs/BoundingBoxHelper'
import { HIGHLIGHTED_TOP_ABSOLUTE_POSITION } from '../libs/HardCodedSizes'
import { shouldShowBoundingBox } from '../libs/shouldShowBoundingBox'
import Box from './Box'
import { DrawerState } from './useDrawerContext'

export function PdfPageHighlight({
  pdfPageHighlight,
  drawerState
}: {
  pdfPageHighlight: PdfPageHighlightData
  drawerState: DrawerState
}) {
  const { boundingBox, style } = pdfPageHighlight
  const { highlightedField } = drawerState
  const highlightedBoundingBoxes = highlightedField?.bounding_boxes || []
  const ref = useRef<HTMLDivElement>(null)
  const topBoundingBox = highlightedBoundingBoxes.sort((a, b) => {
    if (a.page !== b.page) return a.page - b.page
    return a.points?.[0]?.y - b.points?.[0]?.y
  })[0]

  const shouldScroll = isSameBoundingBox({ highlightedBoundingBox: topBoundingBox, boundingBox: boundingBox })

  // Should hide highlight if user actively wanna show another highlight
  const shouldShowHighlight = shouldShowBoundingBox(drawerState, boundingBox)

  useEffect(() => {
    if (!ref.current) return
    if (shouldScroll) ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [ref.current, shouldScroll])
  return (
    <Box>
      <Box
        style={{
          position: 'absolute',
          ...style,
          backgroundColor: undefined,
          top: `calc(${style.top}px - ${HIGHLIGHTED_TOP_ABSOLUTE_POSITION}px)`
        }}
        ref={ref}
      />
      <Box
        style={{
          position: 'absolute',
          ...style
        }}
        visibility={shouldShowHighlight ? 'visible' : 'hidden'}
      />
    </Box>
  )
}
export type PdfPageHighlightData = {
  style: React.CSSProperties
  boundingBox: BoundingBox
}
