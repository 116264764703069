import styled from '@emotion/styled'
import { useState } from 'react'
import { JaguarResultResponse } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { formatRoutePathVitecNextOrderProduct } from '../../../../pure/libs/RoutePath'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { mediumSpacing, smallSpacing } from '../enums/Spacings'
import { useLeopardOrder, useVitecNOOrder } from '../hooks/QueryHooks'
import { useIsProductEnabled } from '../hooks/useIsProductEnabled'
import { useJaguarResultsExperimental } from '../hooks/useJaguarResults'
import { useNavigate } from '../hooks/useNavigate'
import Images from '../libs/Images'
import { isNotStarted } from '../libs/JaguarHelper'
import { Texts } from '../libs/Texts'
import Box from './Box'
import Button from './Button'
import { DashboardListItemDetailsEmptyState } from './DashboardListItemDetailsEmptyState'
import { DashboardListItemDetailsProductNotEnabled } from './DashboardListItemDetailsProductNotEnabled'
import { DashboardListItemDetailsTextComponent } from './DashboardListItemDetailsTextComponent'
import FigmaText from './FigmaText'
import { VitecNextOrderProductButtons } from './VitecNextOrderProductButtons'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'

export function DashboardListItemDetails(props: VitecNextOrderProductProps) {
  const navigate = useNavigate()
  const [shouldShowMore, setShouldShowMore] = useState(false)
  const { data: vitexNextOrder } = useVitecNOOrder(props.vitecNextOrderId)
  const { data: leopardOrder } = useLeopardOrder(
    getLeopardOrderId({ externalEstateId: vitexNextOrder?.estateId, product: props.vitecNextProduct })
  )

  const { DashboardListItemDetailsComponent = DashboardListItemDetailsTextComponent } =
    VitecNextProductInfoItems[props.vitecNextProduct]
  const { data: results } = useJaguarResultsExperimental<JaguarResultResponse>(
    props.vitecNextOrderId,
    props.vitecNextProduct
  )

  const onClick = () => setShouldShowMore(true)
  const Container = shouldShowMore ? Box : CollapsedContainerContainer
  const { data: isVitecNextProductEnabled } = useIsProductEnabled(props)

  if (isVitecNextProductEnabled && !isVitecNextProductEnabled?.isEnabled)
    return (
      <DashboardListItemDetailsProductNotEnabled {...props} isVitecNextProductEnabled={isVitecNextProductEnabled} />
    )

  if (
    props.vitecNextProduct === VitecNextProduct.BASIC_ASSOCIATION_EXPERIMENTAL &&
    !leopardOrder?.extra?.basicAssociationFields
  )
    return <DashboardListItemDetailsEmptyState {...props} />

  if (isNotStarted({ leopardOrder, results })) return <DashboardListItemDetailsEmptyState {...props} />

  return (
    <Box fullWidth>
      <Container fullWidth shouldShowMore={shouldShowMore}>
        <DashboardListItemDetailsComponent {...props} />
      </Container>
      {!shouldShowMore && (
        <Box fullWidth align="center" top spacing={smallSpacing}>
          <Button buttonType="secondary" onClick={onClick} spacing={smallSpacing}>
            <FigmaText textKey={Texts.standardCtaShowMore} />
          </Button>
        </Box>
      )}
      <Box fullWidth direction="row" justify="flex-end" top>
        <Box direction="row" align="center">
          <VitecNextOrderProductButtons
            {...props}
            enableRegenerate={false}
            enableCopy={!VitecNextProductInfoItems[props.vitecNextProduct].enableFulfillment}
            SuffixComponent={() => (
              <Box right spacing={mediumSpacing} style={{ marginLeft: '-15px' }}>
                <Button
                  textKey={Texts.standardCtaOpenDetailPage}
                  onClick={() => navigate(formatRoutePathVitecNextOrderProduct(props))}
                  imageKey={Images.arrowForwardWhite}
                  spacing={smallSpacing}
                />
              </Box>
            )}
          />
        </Box>
      </Box>
    </Box>
  )
}

const CollapsedContainerContainer = styled(Box)<{ shouldShowMore: boolean }>`
  max-height: ${({ shouldShowMore }) => (shouldShowMore ? '100%' : '200px')};
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 1));
    pointer-events: none; /* Ensure the gradient does not interfere with interactions */
    z-index: 1; /* Ensure the gradient is on top of the children */
  }

  /* Ensure children are positioned below the gradient */
  > * {
    position: relative;
    z-index: 0;
  }
`
