import { JaguarResultResponse } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder'

export function isNotStarted({
  leopardOrder,
  results
}: {
  leopardOrder?: LeopardOrder
  results?: JaguarResultResponse
}) {
  return !leopardOrder?.finishedAt && results?.status !== 'success'
}

export function isFinished(result?: JaguarResultResponse) {
  return result?.status === 'success'
}
