import { BasicAssociationResultsResponse } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { getLeopardOrderIdForOrder } from '../../../../pure/libs/getLeopardOrderId'
import { useLeopardOrder, useVitecNOOrder } from './QueryHooks'
import { useDefaultPdfViewerPropSetsForBasicAssociationResults } from './useDefaultPdfViewerPropsForBasicAssociationResults'
import { useJaguarResultExperimental } from './useJaguarResults'

export const usePdfViewerPropSetsForBasicAssociationResults = ({ vitecNextOrderId }: { vitecNextOrderId: string }) => {
  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)
  const { data: leopardOrder } = useLeopardOrder(
    getLeopardOrderIdForOrder({ vitecNextProduct: VitecNextProduct.BASIC_ASSOCIATION_EXPERIMENTAL, vitecNextOrder })
  )

  const { data: jaguarResult } = useJaguarResultExperimental<BasicAssociationResultsResponse>(leopardOrder)

  const { data: pdfViewerPropSets = [] } = useDefaultPdfViewerPropSetsForBasicAssociationResults({
    leopardOrder,
    basicAssociationItems: jaguarResult?.structured_outputs?.basic_association_info
  })

  return pdfViewerPropSets
}
