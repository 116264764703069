import { useEffect } from 'react'
import { useStorageItem } from './useStorageItem'
import useQueryParams from '../libs/useQueryParams'
import { SignInState, SignInSteps } from '../../../../pure/types/SignInTypes'
import { getDefaultSignInState } from './useISignInStateHelper'
import { StorageKey, StorageType } from './useLocalStorageItemHelper'
import { SignInPageQueryParams } from '../../../../pure/types/QueryParamTypes'

export const DEFAULT_SIGN_IN_STATE = {
  data: {
    email: '',
    signInMethods: []
  },
  step: SignInSteps.LANDING
}

export default function useSignInState(signInStateFromProps?: Partial<SignInState>) {
  const queryParams = useQueryParams<SignInPageQueryParams>()

  const { storageItem: localStorageItem, setStorageItem: setLocalStorageItem } = useStorageItem<SignInState>(
    StorageKey.SIGN_IN_STATE,
    DEFAULT_SIGN_IN_STATE as any,
    {
      storageType: StorageType.SESSION_STORAGE
    }
  )

  useEffect(() => {
    const defaultSignInState: SignInState = getDefaultSignInState(queryParams, localStorageItem, signInStateFromProps)
    setSignInState(defaultSignInState)
  }, [])

  const setSignInState = (signInState: SignInState) => setLocalStorageItem(signInState)

  const signInState: SignInState = localStorageItem

  return { signInState, setSignInState }
}
