import { ReactComponent as dashboardLayoutSelectionRowsImg } from './../assets/images/dashboardLayoutSelectionRows.svg'
export const dashboardLayoutSelectionRows = dashboardLayoutSelectionRowsImg
import { ReactComponent as dashboardLayoutSelectionGridImg } from './../assets/images/dashboardLayoutSelectionGrid.svg'
export const dashboardLayoutSelectionGrid = dashboardLayoutSelectionGridImg
import { ReactComponent as regenerateTextImg } from './../assets/images/regenerateText.svg'
export const regenerateText = regenerateTextImg
import { ReactComponent as arrowForwardWhiteImg } from './../assets/images/arrowForwardWhite.svg'
export const arrowForwardWhite = arrowForwardWhiteImg
import { ReactComponent as greenRoundedCheckmarkImg } from './../assets/images/greenRoundedCheckmark.svg'
export const greenRoundedCheckmark = greenRoundedCheckmarkImg
import { ReactComponent as yellowWarningTriangleImg } from './../assets/images/yellowWarningTriangle.svg'
export const yellowWarningTriangle = yellowWarningTriangleImg
import { ReactComponent as warningIconImg } from './../assets/images/warningIcon.svg'
export const warningIcon = warningIconImg
import { ReactComponent as roundCheckIconWhiteImg } from './../assets/images/roundCheckIconWhite.svg'
export const roundCheckIconWhite = roundCheckIconWhiteImg
import { ReactComponent as gazelleInCircleImg } from './../assets/images/gazelleInCircle.svg'
export const gazelleInCircle = gazelleInCircleImg
import pngDocIconImg from './../assets/images/pngDocIcon.png'
export const pngDocIcon = pngDocIconImg
import { ReactComponent as imageCaptionIconImg } from './../assets/images/imageCaptionIcon.svg'
export const imageCaptionIcon = imageCaptionIconImg
import { ReactComponent as headerAndShortDescriptionIconImg } from './../assets/images/headerAndShortDescriptionIcon.svg'
export const headerAndShortDescriptionIcon = headerAndShortDescriptionIconImg
import { ReactComponent as constructionInfoIconImg } from './../assets/images/constructionInfoIcon.svg'
export const constructionInfoIcon = constructionInfoIconImg
import { ReactComponent as areaDescriptionIconImg } from './../assets/images/areaDescriptionIcon.svg'
export const areaDescriptionIcon = areaDescriptionIconImg
import { ReactComponent as adValidationIconImg } from './../assets/images/adValidationIcon.svg'
export const adValidationIcon = adValidationIconImg
import { ReactComponent as energyDeclarationIconImg } from './../assets/images/energyDeclarationIcon.svg'
export const energyDeclarationIcon = energyDeclarationIconImg
import { ReactComponent as standardIIconImg } from './../assets/images/standardIIcon.svg'
export const standardIIcon = standardIIconImg
import { ReactComponent as basicAssiciationIconImg } from './../assets/images/basicAssiciationIcon.svg'
export const basicAssiciationIcon = basicAssiciationIconImg
import pngFeedbackIconImg from './../assets/images/pngFeedbackIcon.png'
export const pngFeedbackIcon = pngFeedbackIconImg
import { ReactComponent as smallFeedbackIconImg } from './../assets/images/smallFeedbackIcon.svg'
export const smallFeedbackIcon = smallFeedbackIconImg
import { ReactComponent as uploadArrowImg } from './../assets/images/uploadArrow.svg'
export const uploadArrow = uploadArrowImg
import { ReactComponent as uploadArrowWhiteImg } from './../assets/images/uploadArrowWhite.svg'
export const uploadArrowWhite = uploadArrowWhiteImg
import { ReactComponent as uploadedArrowImg } from './../assets/images/uploadedArrow.svg'
export const uploadedArrow = uploadedArrowImg
import { ReactComponent as copyWhiteImg } from './../assets/images/copyWhite.svg'
export const copyWhite = copyWhiteImg
import { ReactComponent as copyBlackImg } from './../assets/images/copyBlack.svg'
export const copyBlack = copyBlackImg
import { ReactComponent as redErrorTriangleImg } from './../assets/images/redErrorTriangle.svg'
export const redErrorTriangle = redErrorTriangleImg
import { ReactComponent as arrowForwardImg } from './../assets/images/arrowForward.svg'
export const arrowForward = arrowForwardImg
import { ReactComponent as dashboardArrowRightImg } from './../assets/images/dashboardArrowRight.svg'
export const dashboardArrowRight = dashboardArrowRightImg
import { ReactComponent as dashboardArrowDownImg } from './../assets/images/dashboardArrowDown.svg'
export const dashboardArrowDown = dashboardArrowDownImg
import { ReactComponent as highlightImg } from './../assets/images/highlight.svg'
export const highlight = highlightImg
