import styled from '@emotion/styled'
import { getDownloadURL, ref } from 'firebase/storage'
import { useEffect, useState } from 'react'
import { ONE_SECOND } from '../../../../pure/libs/Consts'
import { getLeopardOrderIdForOrder } from '../../../../pure/libs/getLeopardOrderId'
import { getThumbnailUrl } from '../../../../pure/libs/getThumbnailUrl'
import { isJustCreated } from '../../../../pure/libs/VitecNextOrderItemHelper'
import { BorderRadixes } from '../enums/BorderRadixes'
import { useLeopardOrder, useVitecNOOrder } from '../hooks/QueryHooks'
import { useJaguarResultExperimental } from '../hooks/useJaguarResults'
import { storage } from '../libs/Firebase'
import { VitecNextProductStatusProps } from '../types/VitecNextProductTypes'
import Box from './Box'

export const VitecNextOrderItemStatusFile = (props: VitecNextProductStatusProps) => {
  const { now, vitecNextProduct } = props
  const [imageUrl, setImageUrl] = useState('')
  const [index, setIndex] = useState(0)
  const { data: vitedNextOrder } = useVitecNOOrder(props.vitecNextOrder._id)
  const { data: leopardOrder } = useLeopardOrder(
    getLeopardOrderIdForOrder({ vitecNextProduct, vitecNextOrder: vitedNextOrder })
  )

  const requestId = leopardOrder?.requestId
  const { data: results } = useJaguarResultExperimental(leopardOrder)

  const entries = Object.entries(leopardOrder?.documentUrls || {})

  const hasFiles = entries.length > 0

  useEffect(() => {
    setTimeout(() => setIndex(index + 1), ONE_SECOND * 2)
  }, [index])

  const entry = entries[index % entries.length]
  const url = entry?.[1]

  useEffect(() => {
    if (!url) return
    const thumbnailUrl = getThumbnailUrl(url)
    if (!thumbnailUrl) return
    getDownloadURL(ref(storage, thumbnailUrl))
      .then((url) => setImageUrl(url))
      .catch(() => console.error('Failed to get thumbnail url'))
  }, [url])

  const onClick = () => getDownloadURL(ref(storage, url)).then((url) => window.open(url, '_blank'))

  if (!imageUrl) return null

  return (
    <Box fullWidth>
      {hasFiles && requestId && results?.status === 'processing' && isJustCreated(now, leopardOrder) && (
        <Box fullWidth align="center" onClick={onClick} link>
          <ImageContainer src={imageUrl} style={{ maxWidth: 200, maxHeight: 100 }} />
        </Box>
      )}
    </Box>
  )
}

export const ImageContainer = styled.img`
  border-radius: ${BorderRadixes.regular};
  max-width: 200px;
  max-height: 100px;
`
