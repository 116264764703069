import styled from '@emotion/styled'
import React from 'react'
import { tinyBorderRadius } from '../enums/BorderRadixesDeprecated'
import { getFigmaText } from '../libs/TextRepository'
import Box from './Box'
import ButtonBase from './ButtonBase'
import { ButtonProps } from './ButtonPrimary'
import FigmaTextLoader from './FigmaTextLoader'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import { Texts } from '../libs/Texts'
import FigmaImageContainer from './FigmaImageContainer'
import { smallSpacing } from '../enums/Spacings'

const ButtonSecondary: React.FC<ButtonProps> = (props) => {
  const {
    children,
    className,
    loading,
    textKey = TextsDeprecated.cancelButtonText,
    color = 'blue',
    imageKey,
    text,
    ...buttonProps
  } = props
  const styleTextKey = props.variant === 'outlined' ? Texts.dashboardCtaGenerate : Texts.dashboardCtaGenerate

  return (
    <StyledButton className={className} variant="contained" {...buttonProps} color={color as any}>
      <Box align="center" fullWidth>
        {children || (
          <Box direction="row" align="center">
            {imageKey && (
              <Box>
                <FigmaImageContainer imageKey={imageKey} />
              </Box>
            )}
            <Box left={!!imageKey} spacing={smallSpacing}>
              <FigmaTextLoader
                styleTextKey={styleTextKey}
                textKey={Texts.dashboardCtaGenerate}
                text={text || (textKey ? getFigmaText(textKey) : undefined)}
                loading={loading}
              />
            </Box>
          </Box>
        )}
      </Box>
    </StyledButton>
  )
}

const StyledButton = styled(ButtonBase)<{ spacing?: string }>`
  border-radius: ${tinyBorderRadius};
  white-space: nowrap;
`

export default ButtonSecondary
