import { UploadedFileInfoMetadata } from '../../../../pure/leopard/UploadedFileInfoForServer.js'
import { VITEC_NEXT_IMAGE_ASPECT_RATIO } from '../libs/HardCodedSizes.js'

export function VitecImage({ vitecImage }: { vitecImage?: UploadedFileInfoMetadata }) {
  return (
    <img
      src={vitecImage?.publicUrl}
      alt={vitecImage?.externalId || ''}
      style={{ aspectRatio: VITEC_NEXT_IMAGE_ASPECT_RATIO }}
    ></img>
  )
}
