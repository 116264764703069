import { useState } from 'react'
import { smallSpacing } from '../enums/Spacings.js'
import useAppState from '../hooks/useAppState.js'
import { OnClickVitecNextProductInfoItem, VitecNextProductInfoItemButton } from '../types/VitecNextProductTypes.js'
import Box from './Box.js'

import { IsVitecNextProductEnabledResponse } from '../../../../pure/leopard/IsVitecNextProductEnabledResponse.js'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct.js'
import { createUniqueId } from '../../../../pure/libs/Common.js'
import { MAX_NUMBER_OF_IMAGES } from '../../../../pure/libs/Consts.js'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId.js'
import { isJustCreated, isProcessingUpload } from '../../../../pure/libs/VitecNextOrderItemHelper.js'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes.js'
import { useLeopardOrder, useVitecNOOrder } from '../hooks/QueryHooks.js'
import useAppStateSession from '../hooks/useAppStateSession.js'
import { useJaguarResultExperimental } from '../hooks/useJaguarResults.js'
import { captureException } from '../libs/ErrorHelper.js'
import { getFigmaText } from '../libs/TextRepository.js'
import Button from './Button.js'

type Props = {
  vitecNOOrder: VitecNextOrder
  vitecNextProduct: VitecNextProduct
  button: VitecNextProductInfoItemButton
  isProductEnabled?: IsVitecNextProductEnabledResponse
  index?: number
  now: string
  isLoading?: boolean
}

export const VitecNextOrderItemButton = (props: Props): JSX.Element => {
  const { state: sessionState } = useAppStateSession()
  const { enablePreview = false } = sessionState
  const { button, isProductEnabled, index = 0, vitecNextProduct, now } = props
  const noOfDocuments = isProductEnabled?.noOfDocuments || 0
  const { data: vitecNextOrder = props.vitecNOOrder } = useVitecNOOrder(props.vitecNOOrder._id)
  const [isLoading, setIsLoading] = useState<boolean>()

  const { data: leopardOrder } = useLeopardOrder(
    getLeopardOrderId({ externalEstateId: vitecNextOrder?.estateId, product: vitecNextProduct })
  )

  const { data: results } = useJaguarResultExperimental(leopardOrder)

  const { state } = useAppState()

  const onClick = async (onClick: OnClickVitecNextProductInfoItem) => {
    const requestId = createUniqueId()
    setIsLoading(true)
    onClick({ vitecNextOrder, vitecNextProduct, requestId, state, enablePreview })
      .catch(captureException)
      .finally(() => setIsLoading(false))
  }

  let buttonText = button.getButtonText?.(isProductEnabled) || getFigmaText(button.buttonTextKey)
  buttonText = buttonText?.replace('Punktmarkeri', 'Punktmakert')

  const tooManyImages = results?.status !== 'success' && !!noOfDocuments && noOfDocuments > MAX_NUMBER_OF_IMAGES

  return (
    <Box left={index > 0} spacing={smallSpacing}>
      <Button
        textKey={button.buttonTextKey}
        onClick={() => onClick(button.onClick)}
        text={buttonText}
        disabled={
          isLoading ||
          !isProductEnabled?.isEnabled ||
          (isJustCreated(now, leopardOrder) && isProcessingUpload(results)) ||
          tooManyImages
        }
        loading={props.isLoading || (button.enableLoading && isLoading)}
      />
    </Box>
  )
}
