import { getDownloadURL, ref } from 'firebase/storage'
import { useEffect, useState } from 'react'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { storage } from '../libs/Firebase'

export function useDownloadUrlForLeopardOrder(leopardOrder?: LeopardOrder) {
  const sourceFile = getGsUrlForLeopardOrder(leopardOrder)
  return useDownloadUrl(sourceFile)
}

export function useDownloadUrl(gsUrl?: string) {
  const [url, setUrl] = useState<string>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!gsUrl) return
    getDownloadURL(ref(storage, gsUrl))
      .then((url) => setUrl(url))
      .finally(() => setLoading(false))
  }, [gsUrl])

  return { data: url, loading }
}

export function getGsUrlForLeopardOrder(leopardOrder: LeopardOrder | undefined) {
  return Object.values(leopardOrder?.documentUrls || {})[0]
}
