import { useEffect } from 'react'

export function useClickUpDown({
  items,
  selectedId,
  onClickDown,
  onClickUp
}: {
  items: { id: string }[]
  selectedId: string
  onClickUp: (items: any[], currentIndex: number) => unknown
  onClickDown: (items: any[], currentIndex: number) => unknown
}) {
  useEffect(() => {
    if (items.length === 0) return
    const handleKeyDown = (event: KeyboardEvent) => {
      const currentIndex = items.findIndex((i) => i.id === selectedId)

      if (event.key === 'ArrowDown' && currentIndex < items.length - 1) {
        onClickDown(items, currentIndex)
      } else if (event.key === 'ArrowUp' && currentIndex > 0) {
        onClickUp(items, currentIndex)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [items, selectedId])
}
