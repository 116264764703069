import styled from '@emotion/styled'
import MaterialPopper, { PopperProps } from '@mui/material/Popper'
import { PropsWithChildren } from 'react'
import Colors from '../../../../pure/libs/Colors'
import { BorderRadixes } from '../enums/BorderRadixes'
import { Shadows } from '../enums/Shadows'
import Box from './Box'

const Popper: React.FC<PropsWithChildren<Omit<PopperProps, 'open'>>> = (props) => {
  const { anchorEl, children } = props

  return (
    <MaterialPopper {...props} open={Boolean(anchorEl)} anchorEl={anchorEl}>
      <PopperContainer>{children}</PopperContainer>
    </MaterialPopper>
  )
}

export default Popper

export const PopperContainer = styled(Box)`
  background: ${Colors.white};

  border-radius: ${BorderRadixes.regular};
  box-shadow: ${Shadows.large};
`
