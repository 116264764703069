import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { VitecNextProductInfoItem } from '../types/VitecNextProductTypes'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'

import dayjs from 'dayjs'
import { DASHBOARD_LIST_ITEM_STATUS_VIEW_SIZE } from '../../../../pure/libs/Consts'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { regularSpacing, tinySpacing } from '../enums/Spacings'
import { useLeopardOrder, useVitecNOOrder } from '../hooks/QueryHooks'
import { useIsProductEnabled } from '../hooks/useIsProductEnabled'
import { useJaguarResultExperimental } from '../hooks/useJaguarResults'
import Box from './Box'
import { DashboardListItemStatusView } from './DashboardListItemStatusView'
import { VitecNextOrderLandingItemStatusPlayCircleIcon } from './Icons'
import { LoaderGreen } from './LoaderGreen'
import { VitecNextOrderItemStatus } from './VitecNextOrderItemStatus'

export const DashboardListItemStatus = (props: VitecNextOrderProductProps) => {
  const { vitecNextProduct, vitecNextOrderId, now = dayjs().format() } = props
  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)
  const vitecNextProductInfoItem: VitecNextProductInfoItem = VitecNextProductInfoItems[vitecNextProduct]
  const { StatusComponent = VitecNextOrderItemStatus } = vitecNextProductInfoItem
  const { data: leopardOrder } = useLeopardOrder(
    getLeopardOrderId({ externalEstateId: vitecNextOrder?.estateId, product: props.vitecNextProduct })
  )
  const { data: results } = useJaguarResultExperimental(leopardOrder)

  const { data: isProductEnabled, isLoading: isLoadingProduct } = useIsProductEnabled({
    vitecNextOrderId,
    vitecNextProduct
  })

  if (!vitecNextOrder) return null
  if (isLoadingProduct) return null

  if (results?.status === 'processing')
    return (
      <Box right spacing={tinySpacing}>
        <LoaderGreen size={regularSpacing} />
      </Box>
    )

  if (isProductEnabled?.isEnabled && !results?.status)
    return <VitecNextOrderLandingItemStatusPlayCircleIcon size={DASHBOARD_LIST_ITEM_STATUS_VIEW_SIZE * 2} />

  return (
    <StatusComponent
      vitecNextOrder={vitecNextOrder}
      isProductEnabled={isProductEnabled}
      vitecNextProduct={vitecNextProduct}
      ViewComponent={DashboardListItemStatusView}
      enableLoadingFilesWidget={false}
      now={now}
    />
  )
}
