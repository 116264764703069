import { BasicAssociationItem, BasicAssociationItems } from '../leopard/JaguarExperimentalTypesDerived'
import { NO, YES } from './Consts'
import {
  BasicAssociationField,
  BasicAssociationFields,
  BasicAssociationResponseItemsKey,
  VitecNextOrderProductBasicAssociationItemsPure
} from './VitecNextOrderProductBasicAssociationItemsPure'

export function getBasicAssociationFieldsFromJaguarResult(
  fields: BasicAssociationItems
): BasicAssociationFields | undefined {
  if (!fields) return
  return Object.entries(fields).reduce(
    (a, [key, v]) => {
      const item = VitecNextOrderProductBasicAssociationItemsPure[key as BasicAssociationResponseItemsKey]
      const { enabled = true } = item || {}
      if (!enabled) return a

      let _v: string = v?.value as unknown as string
      if (typeof _v === 'number') _v = (_v as number).toFixed(2).replace('.00', '') || ''

      let value: string = _v?.trim() || ''

      value = item?.format?.(value) || value

      return {
        ...a,
        [key]: {
          // TODO WRITE TEST, should set id to key to get deeplinking to work
          id: key,
          key,
          value,
          isFulfilling: false,
          checked: true,
          fulfilledAt: null,
          bounding_boxes: v?.bounding_boxes || []
        } as BasicAssociationField
      }
    },
    {
      has_board_approval: {
        key: 'has_board_approval',
        value: getYesOrNoStringValue(fields.styregodkjennelse),
        isFulfilling: false,
        checked: true,
        fulfilledAt: null
      },
      has_preemptive: {
        key: 'has_preemptive',
        value: getYesOrNoStringValue(fields.forkjøpsrett),
        isFulfilling: false,
        checked: true,
        fulfilledAt: null
      }
    } as BasicAssociationFields
  )
}

export function getYesOrNoStringValue(item?: BasicAssociationItem): string {
  if (!item) return NO
  const { value: norwegianString } = item
  if (typeof norwegianString !== 'string') return NO
  const value: string = norwegianString
  return value.toLocaleLowerCase() === 'ja' ? YES : NO
}
