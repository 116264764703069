import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { tinySpacing } from '../enums/Spacings'
import Box from './Box'
import DashboardListItem from './DashboardListItem'

export function DashboardList({ vitecNextOrder, now }: { vitecNextOrder: VitecNextOrder; now: string }) {
  return (
    <Box fullWidth>
      {[
        VitecNextProduct.IMAGE_CAPTIONS_EXPERIMENTAL,
        VitecNextProduct.AREA_DESCRIPTION_EXPERIMENTAL,
        VitecNextProduct.CONSTRUCTION_INFO_EXPERIMENTAL,
        VitecNextProduct.DESCRIPTIONS_EXPERIMENTAL,
        VitecNextProduct.SELF_DECLERATION_EXPERIMENTAL,
        VitecNextProduct.BASIC_ASSOCIATION_EXPERIMENTAL
      ]
        .filter((v) => !!v)
        .map((vitecNextProduct, i) => (
          <Box key={i} top spacing={tinySpacing} fullWidth>
            <DashboardListItem
              vitecNextOrderId={vitecNextOrder.orderId}
              vitecNextProduct={vitecNextProduct as VitecNextProduct}
              now={now}
            />
          </Box>
        ))}
    </Box>
  )
}
