import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { useLeopardOrder, useVitecNOOrder } from '../hooks/QueryHooks'
import { useJaguarResultsExperimental } from '../hooks/useJaguarResults'
import Box from './Box'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import Loader from './Loader'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'

export function DashboardListItemDetailsTextComponent<T>(props: VitecNextOrderProductProps) {
  const { vitecNextOrderId, vitecNextProduct } = props
  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)
  const { data: leopardOrder } = useLeopardOrder(
    getLeopardOrderId({ externalEstateId: vitecNextOrder?.estateId, product: vitecNextProduct })
  )

  const { data: jaguarResults, isLoading: isLoadingJaguarResults } = useJaguarResultsExperimental(
    vitecNextOrderId,
    vitecNextProduct
  )

  const text = VitecNextProductInfoItems[props.vitecNextProduct].getText?.({
    jaguarResults,
    leopardOrder
  })

  if (isLoadingJaguarResults) return <Loader />
  if (!leopardOrder) return <Box />
  if (!vitecNextOrder) return <Box />

  return (
    <FigmaTextWithStyle
      Component={FigmaTextWithLinebreaks as any}
      separator={<span />}
      separatorWhenEmptyContent={<br />}
      type="body1"
      text={text?.trim() || ''}
    />
  )
}
