import styled from '@emotion/styled'
import { ConstructionMethodResultsResponse } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import Colors from '../../../../pure/libs/Colors'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import { useLeopardOrder, useVitecNOOrder } from '../hooks/QueryHooks'
import { getGsUrlForLeopardOrder } from '../hooks/useDownloadUrl'
import { useJaguarResultsExperimental } from '../hooks/useJaguarResults'
import { VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH } from '../libs/HardCodedSizes'
import Images from '../libs/Images'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { LoadingPage } from './LoadingPage'
import { VitecNextOrderProductBackButton } from './VitecNextOrderProductBackButton'
import { VitecNextOrderProductButtonsDeprecated as VitecNextOrderProductButtons } from './VitecNextOrderProductButtonsDeprecated'
import { VitecNextOrderProductFeedbackButton } from './VitecNextOrderProductFeedbackButton'
import { VitecNextOrderProductHeader } from './VitecNextOrderProductHeader'
import { VitecNextProductSourceFileInfo } from './VitecNextProductSourceFileInfo'
import { WithPdfDrawer } from './WithPdfDrawer'
import { useDrawerContext } from './useDrawerContext'

export const VitecNextOrderProductContructionInfoExperimental = (props: VitecNextOrderProductProps) => {
  const { vitecNextOrderId, vitecNextProduct } = props
  const { data: vitecNextOrder, isLoading } = useVitecNOOrder(vitecNextOrderId)
  const externalEstateId = vitecNextOrder?.estateId
  const leopardOrderId = getLeopardOrderId({ externalEstateId, product: vitecNextProduct })
  const { data: leopardOrder } = useLeopardOrder(leopardOrderId)
  console.log('leopardOrder', leopardOrder)
  const drawerContext = useDrawerContext('construction_info')
  const gsUrl = getGsUrlForLeopardOrder(leopardOrder)
  const { data } = useJaguarResultsExperimental<ConstructionMethodResultsResponse>(vitecNextOrderId, vitecNextProduct)

  const status = data?.status

  const text = data?.text_outputs?.constructionMethod

  if (isLoading) return <LoadingPage />
  if (!leopardOrder) return <Box />
  if (!vitecNextOrder) return <Box />

  return (
    <WithPdfDrawer pdfViewerPropSets={[{ gsUrl }]} drawerContext={drawerContext}>
      <Box fullWidth fullPadding direction="row" justify="space-between" spacing={bigSpacing}>
        <Box>
          <VitecNextOrderProductBackButton vitecNextOrderId={vitecNextOrderId} />
        </Box>
        <Box fullWidth width={`${VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH}px`}>
          <VitecNextOrderProductHeader
            vitecNextOrder={vitecNextOrder}
            headerTextKey={Texts.ConstructionCiHeaderText}
            vitecNextProduct={vitecNextProduct}
          />
          <Box top>
            <FigmaImageContainer imageKey={Images.gazelleInCircle} />
          </Box>
          <Box fullWidth top spacing={smallSpacing}>
            <VitecNextOrderProductButtons
              vitecNextOrderId={vitecNextOrderId}
              vitecNextProduct={vitecNextProduct}
              fulfillMentSuccessText={getFigmaText(Texts.standardNotificationUploadComplete)}
            />
          </Box>

          <Box fullWidth fullPadding>
            <Box fullWidth onClick={() => drawerContext.open()}>
              <VitecNextProductSourceFileInfo leopardOrder={leopardOrder} />
            </Box>
          </Box>

          {!status && !text && <LoadingPage />}
          {status === 'success' && (
            <Container fullWidth fullPadding>
              <Box direction="row" align="center">
                <FigmaText textKey={Texts.ConstructionCiTextHeader} />
              </Box>
              <Box top>
                <VitecNextOrderProductFeedbackButton {...props} />
              </Box>
              <Box fullWidth top>
                <FigmaTextWithStyle
                  Component={FigmaTextWithLinebreaks as any}
                  separator={<span />}
                  separatorWhenEmptyContent={<br />}
                  type="body1"
                  text={text}
                />
              </Box>
            </Container>
          )}
        </Box>
        <Box />
      </Box>
    </WithPdfDrawer>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`
