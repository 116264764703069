import { useNavigate } from 'react-router-dom'
import { formatRoutePathVitexNextOrder } from '../../../../pure/libs/RoutePath'
import { smallSpacing } from '../enums/Spacings'
import { Texts } from '../libs/Texts'
import Box from './Box'
import { VitecNextOrderArrowBackIcon } from './Icons'
import { NoWrapFigmaText } from './StyledComponents'

export function VitecNextOrderProductBackButton({ vitecNextOrderId }: { vitecNextOrderId: string }) {
  const onClick = () => (window.location.href = formatRoutePathVitexNextOrder(vitecNextOrderId))
  return (
    <Box direction="row" link onClick={onClick}>
      <Box>
        <VitecNextOrderArrowBackIcon />
      </Box>
      <Box left spacing={smallSpacing}>
        <NoWrapFigmaText textKey={Texts.standardBack} />
      </Box>
    </Box>
  )
}
