import dayjs from 'dayjs'
import React from 'react'
import { bigSpacing, reallyBigSpacing } from '../enums/Spacings'
import { useVitecNOOrder } from '../hooks/QueryHooks'
import useAppStateSession from '../hooks/useAppStateSession'
import { useAreGazelleProductsEnabled } from '../hooks/useIsProductEnabled'
import { DASHBOARD_MIN_WIDTH } from '../libs/HardCodedSizes'
import { HardCodedTexts, Texts } from '../libs/Texts'
import Box from './Box'
import { DashboardGrid } from './DashboardGrid'
import { DefaultDashboardLayout } from './DashboardLayoutSelectionButton'
import { DashboardList } from './DashboardList'
import { DashboardPreviewDeprecated } from './DashboardPreviewDeprecated'
import { DashboardStatus } from './DashboardStatus'
import FigmaText from './FigmaText'
import { Layout } from './Layout'
import { LayoutLoadingPage } from './LoadingPage'
import MenuVitecNext from './MenuVitecNext'
import { WithAdValidationPdfDrawer } from './WithAdValidationPdfDrawer'
import { useDrawerContext } from './useDrawerContext'

export const Dashboard: React.FC<{ vitecNextOrderId: string }> = ({ vitecNextOrderId }) => {
  const drawerContext = useDrawerContext('ad_validation')
  const now = dayjs().format()

  const { data: vitecNextOrder, isLoading: isLoadingOrder } = useVitecNOOrder(vitecNextOrderId)
  const { state } = useAppStateSession()
  const { dashboardLayout = DefaultDashboardLayout } = state

  const { isLoading: isLoadingAreProductsEnabled } = useAreGazelleProductsEnabled({ vitecNextOrderId })

  const isLoading = isLoadingOrder || isLoadingAreProductsEnabled

  if (!vitecNextOrder) return <LayoutLoadingPage />

  return (
    <Layout enableFooter={false} MenuComponent={MenuVitecNext} enableRightBottomImage={false}>
      <WithAdValidationPdfDrawer vitecNextOrderId={vitecNextOrderId} drawerContext={drawerContext}>
        <Box fullWidth align="center" spacing={bigSpacing} top bottom>
          <Box width={`${DASHBOARD_MIN_WIDTH}px`} left right spacing={reallyBigSpacing}>
            <Box>
              <FigmaText textKey={HardCodedTexts.orderViewHeader} text={vitecNextOrder.address} />
            </Box>
            <Box top fullWidth direction="row" align="center" justify="space-between">
              <Box>
                <FigmaText textKey={Texts.dashboardFeaturesHeader} />
              </Box>
              <Box>
                <DashboardPreviewDeprecated />
              </Box>
            </Box>
            <Box top fullWidth>
              <DashboardStatus vitecNextOrder={vitecNextOrder} isLoading={isLoading} />
            </Box>
            <Box fullWidth top>
              {dashboardLayout === 'grid' && <DashboardGrid vitecNextOrder={vitecNextOrder} now={now} />}
              {dashboardLayout === 'list' && <DashboardList vitecNextOrder={vitecNextOrder} now={now} />}
            </Box>
          </Box>
        </Box>
      </WithAdValidationPdfDrawer>
    </Layout>
  )
}
