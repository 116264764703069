/* eslint-disable prettier/prettier */
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore'
import Collections from '../../../../pure/libs/Collections.js'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId.js'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder.js'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes.js'
import { Ad, CreateUserRequest, ExternalSession, Id, JaguarSession, JaguarSessionRequest, Organization, User } from '../../../../pure/types/types.js'
import { State } from '../hooks/useAppState.js'
import { db } from './Firebase.js'
import * as Repository from './FirebaseStorageClientForWebApp.js'
import { getCollectionData } from '../../../../pure/libs/FirebaseStorageClientBaseHelper.js'
import { SetOptions } from 'firebase-admin/firestore'
export const getUser = (id: Id): Promise<User | undefined> => Repository.getObject(id, Collections.USERS) as Promise<User | undefined>
export const updateUser = (user: User): Promise<User> => Repository.updateObject(user, Collections.USERS)
export const createUser = (user: CreateUserRequest, state: State): Promise<User> => Repository.createObject(user, Collections.USERS, state) as Promise<User>

export const createJaguarSession = (s: JaguarSessionRequest, state: State): Promise<JaguarSession> => Repository.createObject(s, Collections.JAGUAR_SESSIONS, state) as Promise<JaguarSession>
export const updateJaguarSession = (s: JaguarSession): Promise<JaguarSession | undefined> => Repository.updateObject(s, Collections.JAGUAR_SESSIONS)
export const removeJaguarSession = (s: JaguarSession): Promise<unknown> => Repository.removeObject(s, Collections.JAGUAR_SESSIONS)
export const getJaguarSession = (id: Id): Promise<JaguarSession | undefined> => Repository.getObject(id, Collections.JAGUAR_SESSIONS) as Promise<JaguarSession | undefined>
export const getJaguarSessions = (uid: string): Promise<JaguarSession[]> => Repository.getAllObjectsWithUserIdQuery(uid, Collections.JAGUAR_SESSIONS) as unknown as Promise<JaguarSession[]>

export const updateOrganization = (org: Organization): Promise<Organization> => Repository.updateObject(org, Collections.ORGANIZATIONS)

export const getExternalSession = (id: Id): Promise<ExternalSession | undefined> => Repository.getObject(id, Collections.EXTERNAL_SESSIONS) as Promise<ExternalSession | undefined>
export const updateExternalSession = (org: ExternalSession): Promise<ExternalSession> => Repository.updateObject(org, Collections.EXTERNAL_SESSIONS)

export const getAd = (id: Id): Promise<Ad | undefined> => Repository.getObject(id, Collections.ADS) as Promise<Ad | undefined>
export const updateAd = (ad: Ad): Promise<Ad> => Repository.updateObject(ad, Collections.ADS)
export const removeAd = (ad: Ad) => Repository.removeObject(ad, Collections.ADS)

export const getVitecNOOrder = (id: string): Promise<VitecNextOrder | undefined> => Repository.getObject(id, Collections.VITEC_NO_ORDER) as Promise<VitecNextOrder | undefined>

export const updateVitecNOOrder = (o: VitecNextOrder): Promise<VitecNextOrder> => Repository.updateObject(o, Collections.VITEC_NO_ORDER)

export const upsertLeopardOrder = (leopardOrder: LeopardOrder, setOptions: SetOptions = {merge: false}) => 
        setDoc(doc(db, Collections.LEOPARD_ORDERS, getLeopardOrderId(leopardOrder)), leopardOrder, setOptions)