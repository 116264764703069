import dayjs from 'dayjs'
import { JaguarResultResponse } from '../leopard/JaguarExperimentalTypesDerived'
import { LeopardOrder } from '../types/LeopardOrder'
import { SEVEN_MINUTES_IN_SECONDS } from './Consts'

type Config = {
  longOrderTimeoutSeconds: number
}

type IsCreatedLongTimeAgoArgs = {
  now: string
  o?: LeopardOrder
  config?: Config
}

export function isProcessingUpload(o?: JaguarResultResponse) {
  return ['processing'].includes(o?.status || '')
}

export function isTimedoutLeopardOrder({
  now,
  o,
  results
}: IsCreatedLongTimeAgoArgs & { results?: JaguarResultResponse }) {
  return isProcessingUpload(results) && isCreatedLongTimeAgo({ now, o })
}

export const isCreatedLongTimeAgo = ({ now, o, config }: IsCreatedLongTimeAgoArgs) =>
  dayjs(o?.createdAt)
    .add(config?.longOrderTimeoutSeconds || SEVEN_MINUTES_IN_SECONDS, 'seconds')
    .isBefore(dayjs(now))

export const isJustCreated = (now: string, o?: LeopardOrder) => !isCreatedLongTimeAgo({ now, o })
