import MenuVitecNext from './MenuVitecNext'
import useFirebaseUser from './useFirebaseUser'

export default function Menu() {
  const firebaseUser = useFirebaseUser().data

  if (!firebaseUser?.email) return <MenuVitecNext />

  return <MenuVitecNext />
}
