import { doc, updateDoc } from 'firebase/firestore'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import Collections from '../../../../pure/libs/Collections'
import { BasicAssociationFields } from '../../../../pure/libs/VitecNextOrderProductBasicAssociationItemsPure'
import { db } from '../libs/Firebase'
import { useLeopardOrder, useVitecNOOrder } from './QueryHooks'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { useJaguarResultsExperimental } from './useJaguarResults'
import {
  BasicAssociationItems,
  BasicAssociationResultsResponse
} from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { getBasicAssociationFieldsFromJaguarResult } from '../../../../pure/libs/getBasicAssociationFieldsFromJaguarResult'

export const useBasicAssociaionFieldsState = (vitecNextOrderId: string) => {
  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)
  const vitecNextProduct = VitecNextProduct.BASIC_ASSOCIATION_EXPERIMENTAL

  const { data: leopardOrder } = useLeopardOrder(
    getLeopardOrderId({ externalEstateId: vitecNextOrder?.estateId, product: vitecNextProduct })
  )

  const { data: jaguarResult } = useJaguarResultsExperimental<BasicAssociationResultsResponse>(
    vitecNextOrderId,
    vitecNextProduct
  )

  const basicAssociationFields =
    leopardOrder?.extra?.basicAssociationFields ||
    getBasicAssociationFieldsFromJaguarResult(
      jaguarResult?.structured_outputs?.basic_association_info as BasicAssociationItems
    )

  const updateBasicAssociationFields = (fields: BasicAssociationFields) =>
    vitecNextOrder &&
    leopardOrder &&
    updateDoc(
      doc(
        db,
        Collections.LEOPARD_ORDERS,
        getLeopardOrderId({ externalEstateId: vitecNextOrder.estateId, product: vitecNextProduct })
      ),
      `extra.basicAssociationFields`,
      fields
    )

  return { basicAssociationFields, updateBasicAssociationFields }
}
