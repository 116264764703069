import * as React from 'react'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import { regularSpacing } from '../enums/Spacings'
import { BorderRadixes } from '../enums/BorderRadixes'
import { SxProps } from '@mui/material'

export default function ProgressBar({ color }: { color?: string }) {
  const [progress, setProgress] = React.useState(0)

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const diff = Math.random() * 0.5
        return Math.min(oldProgress + diff, 100)
      })
    }, 500)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return ProgressBarView({ progress, color })
}

export function ProgressBarView({
  progress,
  color = 'success',
  sx
}: {
  progress: number
  color?: string
  sx?: SxProps
}) {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress
        variant="determinate"
        value={progress}
        color={color as any}
        sx={{
          height: regularSpacing,
          borderRadius: BorderRadixes.subtle,
          ...sx
        }}
      />
    </Box>
  )
}
